<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      兌換點數
      <v-badge bordered color="info" :content="cartCount" overlap>
        <v-btn clock color="primary" @click="openDialog"> 兌換清單 </v-btn>
      </v-badge>
    </h3>
    <v-row align="center">
      <v-col cols="6" sm="3">
        <v-select
          label="店點"
          hide-details
          v-model="branch"
          :items="[
            { text: '請選擇', value: '', disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
          ]"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels>
        <template v-for="(group, index) in _.groupBy(filterProducts, 'group')">
          <v-expansion-panel v-if="group.length">
            <v-expansion-panel-header
              color="info"
              class="font-weight-bold white--text"
            >
              <template v-if="index == 'NaN'">其他</template>
              <template v-else>
                {{ index * 10 + 1 }} ~ {{ index * 10 + 10 }}
              </template>

              <template v-slot:actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <template
                  v-for="stall of group.filter((stall) => stall.product)"
                >
                  <v-list-item class="my-1">
                    <v-row align="center">
                      <v-col cols="auto">
                        {{ stall.machine }} ) {{ stall.product.name }}
                      </v-col>
                      <v-col cols="auto" class="ml-auto">
                        <v-text-field
                          :value="cart[stall.product._id]"
                          type="number"
                          dense
                          hide-details
                          outlined
                          class="input-width"
                          prepend-icon="mdi-minus"
                          append-outer-icon="mdi-plus"
                          @change="update(stall.product._id, $event)"
                          @click:prepend="minus(stall.product._id)"
                          @click:append-outer="plus(stall.product._id)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-row>
    <v-dialog v-model="dialogList" @click:outside="stopScan">
      <v-card>
        <v-card-title>兌換清單</v-card-title>
        <v-card-text>
          <div
            v-for="(amount, pid) of cart"
            class="text-subtitle-1"
            v-if="amount"
          >
            [ {{ products[pid].points }} ] {{ products[pid].name }} x
            {{ amount }}
          </div>
          <v-spacer></v-spacer>
          <div class="text-right text-subtitle-1 font-weight-bold">
            共 {{ cartCount }} 樣
          </div>
          <div class="text-right text-subtitle-1 font-weight-bold">
            兌換 {{ cartPoint }} 點
          </div>

          <v-text-field
            class="my-1"
            outlined
            label="會員編號"
            dense
            hide-details
            v-model="user"
            append-icon="mdi-qrcode-scan"
            @click:append="activeScan = true"
          ></v-text-field>

          <QrcodeStream
            class="qrcode-scanner"
            ref="scanner"
            v-if="activeScan"
            @detect="onDetect"
          ></QrcodeStream>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="clearCart" class="bt-text-large"
            >清空</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="confirm" class="bt-text-large"
            >確認</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import machineNum from "@/config/machineNum.json";
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "TradePoint",
  mixins: [util],
  components: {
    QrcodeStream,
  },
  data: () => ({
    dialogList: false,
    branch: "",
    products: {},
    data: [],
    cart: {},
    user: "",
    keyword: "",
    activeScan: false,
  }),
  computed: {
    _: () => _,
    filterProducts() {
      let stalls = this.data;
      if (this.keyword) {
        stalls = _.filter(stalls, (stall) => {
          return (
            stall.product &&
            stall.product.name
              .toLowerCase()
              .includes(this.keyword.toLowerCase())
          );
        });
      }
      return stalls;
    },
    cartCount() {
      return _.sum(_.values(this.cart)) || "0";
    },
    cartPoint() {
      return (
        _.sum(
          _.map(this.cart, (amount, pid) => amount * this.products[pid].points)
        ) || 0
      );
    },
  },
  created() {
    this.branch = this.$store.state.branch;
    this.$nextTick(async () => {
      await this.getProducts();
      this.load();
    });
  },
  methods: {
    async onDetect(val) {
      let decode = (await val).content;
      if (decode.length == 16) {
        this.user = decode;
        this.stopScan();
      }
    },
    stopScan() {
      this.activeScan = false;
    },
    openDialog() {
      if (parseInt(this.cartCount)) this.dialogList = true;
    },
    clearCart() {
      this.cart = {};
      this.user = null;
      this.stopScan();
      this.dialogList = false;
    },
    async confirm() {
      if (!this.user) {
        this.$toast.error("請輸入會員編號");
        return;
      }
      try {
        await this.axios.post(`/dashboard/tradePoint`, {
          branch: this.branch,
          userID: this.user,
          point: this.cartPoint,
          payload: _.map(this.cart, (amount, productId) => {
            return {
              product: this.products[productId],
              amount: amount,
            };
          }),
          type: "兌換點數",
        });

        this.$toast.success("兌換成功");
        this.clearCart();
        this.load();
      } catch (error) {
        console.log(error);
        this.$toast.error("兌換失敗");
      }
    },
    update(productId, value) {
      value = parseInt(value);
      this.$set(this.cart, productId, value);
    },
    minus(id) {
      if (this.cart[id] > 0) {
        this.cart[id]--;
      }
    },
    plus(id) {
      if (!this.cart[id]) {
        this.$set(this.cart, id, 0);
      }
      this.cart[id]++;
    },
    async getProducts() {
      const { data } = await this.axios.get(`/product?type=機台商品`);
      let products = _.mapKeys(data, "_id");
      this.products = products;
    },
    async load() {
      if (!this.branch) return;
      this.cart = {};
      const { data } = await this.axios.get(`/stall`);
      // get array from 1 to 67
      let stall = machineNum[this.branch];

      stall = stall.map((item) => {
        let product_id =
          _.find(data, { branch: this.branch, machine: item.toString() })
            ?.productId || "";

        return {
          branch: this.branch,
          machine: item.toString(),
          product: product_id ? this.products[product_id] : null,
          group: parseInt(item / 10),
        };
      });

      this.data = stall;
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
.qrcode-scanner {
  margin-top: 20px;
  border: 3px solid rgb(229, 5, 5);
  border-radius: 5px;
}
.input-width {
  width: 110px;

  ::v-deep .v-input__slot {
    padding: 5px !important;
    input {
      text-align: center;
    }
  }
}

::v-deep .v-expansion-panel-header--active {
  min-height: 48px;
}
</style>
