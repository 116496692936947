<script>
import dayjs from "dayjs";
import * as XLSX from "xlsx";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default {
  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
        navigator.userAgent
      );
    },
    datePickerArr() {
      return {
        today: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
        yesterday: [
          dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        ],
        thisMonth: [
          dayjs().startOf("month").format("YYYY-MM-DD"),
          dayjs().endOf("month").format("YYYY-MM-DD"),
        ],
        prevMonth: [
          dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
        ],
      };
    },
    accessBranch() {
      switch (this.$store.state.user.角色) {
        case "主管":
        case "店長":
          return [
            { text: "文賢店", value: "文賢" },
            { text: "崇善店", value: "崇善" },
            { text: "奇美店", value: "奇美" },
            { text: "友愛店", value: "友愛" },
          ];
        case "友愛":
          return [{ text: "友愛店", value: "友愛" }];
        case "店員":
        case "採購":
        default:
          return [
            { text: "文賢店", value: "文賢" },
            { text: "崇善店", value: "崇善" },
            { text: "奇美店", value: "奇美" },
          ];
      }
    },
    accessUnit() {
      switch (this.$store.state.user.角色) {
        case "主管":
        case "店長":
          return [
            { text: "文賢店", value: "文賢" },
            { text: "崇善店", value: "崇善" },
            { text: "奇美店", value: "奇美" },
            { text: "友愛店", value: "友愛" },
            { text: "總倉", value: "總倉" },
          ];
        case "友愛":
          return [{ text: "友愛店", value: "友愛" }];
        case "店員":
        case "採購":
        default:
          return [
            { text: "文賢店", value: "文賢" },
            { text: "崇善店", value: "崇善" },
            { text: "奇美店", value: "奇美" },
            { text: "總倉", value: "總倉" },
          ];
      }
    },
  },
  methods: {
    async getMe() {
      try {
        await this.axios.get("/dashboard/me");
      } catch (error) {}
    },
    async getProducts(type, storeID) {
      let { data } = await this.axios({
        method: "get",
        alone: true,
        url: `https://claw-media.pre-stage.cc/${type}-${storeID}.json`,
      });
      return data.filter((o) => o.上架);
    },
    async getRate(storeID) {
      let { data } = await this.axios({
        method: "get",
        alone: true,
        url: `https://claw-media.pre-stage.cc/兌換關係-${storeID}.json`,
      });
      return data;
    },
    async getData(path, query) {
      switch (this.$store.state.user.角色) {
        case "主管":
        case "店長": {
          const { data } = await this.axios.get(path, { params: query });
          return data;
        }
        case "友愛": {
          const { data } = await this.axios.get(path, {
            params: { ...query, branch: "友愛" },
          });
          return data;
        }
        case "店員":
        case "採購":
        default: {
          const { data } = await this.axios.get(path, { params: query });
          return data.filter((o) => o.branch !== "友愛");
        }
      }
    },
    isBetween({ date, start, end }) {
      return (
        dayjs(date).isSameOrAfter(start, "day") &&
        dayjs(date).isSameOrBefore(end, "day")
      );
    },
    sortDateDesc(sortKey) {
      return (a, b) => {
        if (dayjs(a[sortKey]).isAfter(dayjs(b[sortKey]))) {
          return -1;
        }
        if (dayjs(a[sortKey]).isBefore(dayjs(b[sortKey]))) {
          return 1;
        }
        return 0;
      };
    },
    formatPrice(value) {
      if (Number.isNaN(Number(value)) || value === null) return "";
      return new Intl.NumberFormat().format(value);
    },
    includesIgnoreCase(source, keyword) {
      if (!source || !keyword) {
        return false;
      }
      return source.toUpperCase().includes(keyword.toUpperCase());
    },
    exportToExcel(data, fileName) {
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = XLSX.utils.book_new();
      const today = dayjs().format("YYYY-MM-DD_HH-mm");
      XLSX.utils.book_append_sheet(workBook, workSheet, "sheet 1");
      XLSX.writeFile(workBook, `${fileName}_${today}.xlsx`);
    },
    exportToExcelSheets(sheets, fileName) {
      const workBook = XLSX.utils.book_new();
      const today = dayjs().format("YYYY-MM-DD_HH-mm");

      sheets.forEach(({ data, sheetName }) => {
        const workSheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
      });
      XLSX.writeFile(workBook, `${fileName}_${today}.xlsx`);
    },
  },
};
</script>
