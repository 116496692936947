var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"page-title"},[_vm._v("機台統計")]),_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-select',{attrs:{"label":"店點","items":[
          { text: '請選擇', value: null, disabled: true },
          { text: '文賢店', value: '文賢' },
          { text: '崇善店', value: '崇善' },
          { text: '奇美店', value: '奇美' },
        ],"outlined":"","dense":"","hide-details":""},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})],1),_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-select',{attrs:{"label":"數值","items":[
          { text: '請選擇', value: null, disabled: true },
          { text: '金額', value: '金額' },
          { text: '電眼', value: '電眼' },
        ],"outlined":"","dense":"","hide-details":""},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatedDate,"label":"日期區間","outlined":"","readonly":"","dense":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":"","locale":"zh-tw"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.date);
              _vm.load();}}},[_vm._v(" 確定 ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"mobile-breakpoint":0,"item-key":"index","hide-default-footer":"","footer-props":{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [-1],
        },"height":'80vh',"fixed-header":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',_vm._l((_vm.headers),function(header){return _c('td',{staticClass:"text-center"},[(header.value == 'machine')?[_vm._v(" "+_vm._s(item[header.value] || "-")+" ")]:(header.key == 'sum')?[_vm._v(" "+_vm._s(_vm.format(item[header.key][_vm.key] || 0))+" ")]:[_c('div',{class:_vm.warnBg(item[header.key]?.[_vm.key])},[_vm._v(" "+_vm._s(item[header.key]?.[_vm.key] ? _vm.format(item[header.key][_vm.key]) : "-")+" ")])]],2)}),0)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }