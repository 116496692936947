var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"page-title d-flex justify-space-between align-center"},[_vm._v(" 兌換換抽獎品（櫃檯用） "),_c('v-badge',{attrs:{"bordered":"","color":"info","content":_vm.cartCount,"overlap":""}},[_c('v-btn',{attrs:{"clock":"","color":"primary"},on:{"click":_vm.openDialog}},[_vm._v(" 兌換清單 ")])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-select',{attrs:{"label":"店點","hide-details":"","items":[
          { text: '請選擇', value: '', disabled: true },
          { text: '文賢店', value: '文賢' },
          { text: '崇善店', value: '崇善' },
          { text: '奇美店', value: '奇美' },
        ],"outlined":"","dense":""},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"機台","clearable":""},model:{value:(_vm.machine),callback:function ($$v) {_vm.machine=$$v},expression:"machine"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"關鍵字","clearable":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1),_c('v-row',[_c('v-expansion-panels',[_vm._l((_vm.filterStalls),function(stall,index){return [(stall && stall.awards?.length)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"font-weight-bold white--text",attrs:{"color":"info"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}],null,true)},[[_vm._v(" "+_vm._s(stall.machine)+" "),(_vm.hint[_vm.branch] && _vm.hint[_vm.branch][stall.machine])?_c('span',[_vm._v(" "+_vm._s(_vm.hint[_vm.branch][stall.machine] ?? "")+" ")]):_vm._e()]],2),_c('v-expansion-panel-content',[_c('v-list',[_vm._l((stall.awards.filter(
                  (award) => award.lottery !== 0
                )),function(award){return [(_vm.productMap[award.productId])?_c('v-list-item',{staticClass:"my-1"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.productMap[award.productId].name)+" "),_c('br'),_c('span',{staticClass:"caption"},[_vm._v(" 抽數: "+_vm._s(award.lottery)+" \\ 庫存: "+_vm._s(_vm.stockMap[award.productId]?.amount ?? 0)+" ")])]),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"input-width",attrs:{"value":_vm.cart[`${award.productId}__${stall.machine}`],"type":"number","dense":"","hide-details":"","outlined":"","prepend-icon":"mdi-minus","append-outer-icon":"mdi-plus"},on:{"change":function($event){return _vm.update(
                            `${award.productId}__${stall.machine}`,
                            $event
                          )},"click:prepend":function($event){return _vm.minus(`${award.productId}__${stall.machine}`)},"click:append-outer":function($event){return _vm.plus(
                            `${award.productId}__${stall.machine}`,
                            award.lottery < _vm.stockMap[award.productId]?.amount
                              ? award.lottery
                              : _vm.stockMap[award.productId]?.amount
                          )}}})],1)],1)],1):_vm._e(),_c('v-divider')]})],2)],1)],1):_vm._e()]})],2)],1),_c('v-dialog',{attrs:{"max-width":"400"},on:{"click:outside":_vm.stopScan},model:{value:(_vm.dialogList),callback:function ($$v) {_vm.dialogList=$$v},expression:"dialogList"}},[_c('v-card',[_c('v-card-title',[_vm._v("兌換清單")]),_c('v-card-text',[_vm._l((_vm.cart),function(amount,id){return (amount)?_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.productMap[id.split("__")[0]].name)+" x "+_vm._s(amount)+" ")]):_vm._e()}),_c('v-spacer'),_c('div',{staticClass:"text-right text-subtitle-1 font-weight-bold"},[_vm._v(" 共 "+_vm._s(_vm.cartCount)+" 樣 ")])],2),_c('v-card-actions',[_c('v-btn',{staticClass:"bt-text-large",attrs:{"text":"","color":"error"},on:{"click":_vm.clearCart}},[_vm._v("清空")]),_c('v-spacer'),_c('v-btn',{staticClass:"bt-text-large",attrs:{"text":"","color":"primary"},on:{"click":_vm.confirm}},[_vm._v("確認")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }