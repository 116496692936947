<template>
  <v-container fluid>
    <h3 class="page-title">機台統計</h3>
    <v-row>
      <v-col cols="3" md="3">
        <v-select
          label="店點"
          v-model="branch"
          :items="[
            { text: '請選擇', value: null, disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
          ]"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="3" md="3">
        <v-select
          label="數值"
          v-model="key"
          :items="[
            { text: '請選擇', value: null, disabled: true },
            { text: '金額', value: '金額' },
            { text: '電眼', value: '電眼' },
          ]"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatedDate"
              label="日期區間"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.menu.save(date);
                load();
              "
            >
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
          :height="'80vh'"
          fixed-header
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center" v-for="header of headers">
                <template v-if="header.value == 'machine'">
                  {{ item[header.value] || "-" }}
                </template>
                <template v-else-if="header.key == 'sum'">
                  {{ format(item[header.key][key] || 0) }}
                </template>
                <template v-else>
                  <div :class="warnBg(item[header.key]?.[key])">
                    {{
                      item[header.key]?.[key]
                        ? format(item[header.key][key])
                        : "-"
                    }}
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import machineNum from "@/config/machineNum.json";

export default {
  name: "MachineStatistic",
  data: () => ({
    ready: false,
    branch: null,
    date: [],
    menu: false,
    data: [],
    key: "金額",
  }),
  computed: {
    headers() {
      let arr = [
        {
          text: "機台",
          value: "machine",
          align: "center",
        },
        {
          text: "小記",
          value: "sum." + this.key,
          key: "sum",
          align: "center",
        },
      ];

      this.daysRange.forEach((date) => {
        arr.push({
          text: date,
          value: `${date}.${this.key}`,
          key: date,
          align: "center",
        });
      });
      return arr;
    },
    formatedDate() {
      return this.date[0] && this.date[1]
        ? dayjs(this.date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(this.date[1]).format("YYYY-MM-DD")
        : "";
    },
    daysRange() {
      const range = [];
      let current = this.date[0];
      while (dayjs(current) <= dayjs(this.date[1])) {
        range.push(current);
        current = dayjs(current).add(1, "days").format("YYYY-MM-DD");
      }
      return range;
    },
  },
  created() {
    this.branch = this.$store.state.branch || this.$store.state.storeID;
    this.date[0] = dayjs().add(-6, "day").format("YYYY-MM-DD");
    this.date[1] = dayjs().format("YYYY-MM-DD");
    this.$nextTick(() => {
      this.ready = true;
      this.load();
    });
  },
  methods: {
    async load() {
      if (!this.ready) return;
      if (!this.date[0] || !this.date[1]) return;
      if (!this.branch || !this.date) {
        this.$toast.error(`請選擇店點及日期`);
        return;
      }
      let prevDate = dayjs(this.date[0]).add(-1, "day").format("YYYY-MM-DD");
      const res = await this.axios.get(
        `/dashboard/machine/${this.$store.state.user.store}/${this.branch}/${prevDate},${this.date[1]}`
      );
      let records = res.data;

      let mapRecords = {};
      records.forEach((record) => {
        mapRecords[`${record.date}|${record.machine}`] = _.cloneDeep(record);
      });

      records.forEach((record) => {
        let date = record.date;
        let machine = record.machine;
        let prevDate = dayjs(date).add(-1, "day").format("YYYY-MM-DD");

        record.金額 =
          mapRecords[`${date}|${machine}`]?.金額 &&
          mapRecords[`${prevDate}|${machine}`]?.金額
            ? mapRecords[`${date}|${machine}`]?.金額 * 10 -
              mapRecords[`${prevDate}|${machine}`]?.金額 * 10
            : 0;
        record.電眼 =
          mapRecords[`${date}|${machine}`]?.電眼 &&
          mapRecords[`${prevDate}|${machine}`]?.電眼
            ? mapRecords[`${date}|${machine}`]?.電眼 -
              mapRecords[`${prevDate}|${machine}`]?.電眼
            : 0;
      });

      let data = [{}];
      machineNum[this.branch].forEach((machine, index) => {
        let dates = records.filter((row) => row.machine == machine);
        data[index] = {
          machine: machine,
          sum: {
            金額: _.sumBy(dates, "金額"),
            電眼: _.sumBy(dates, "電眼"),
          },
          ..._.keyBy(dates, "date"),
        };
      });

      let dateSum = _.groupBy(records, "date");
      dateSum = _.mapValues(dateSum, (o) => ({
        金額: _.sumBy(o, "金額"),
        電眼: _.sumBy(o, "電眼"),
      }));
      data.unshift({
        machine: "總和",
        sum: {
          金額: _.sumBy(records, "金額"),
          電眼: _.sumBy(records, "電眼"),
        },
        ...dateSum,
      });

      this.data = data;
    },
    async updateValue(props, type) {
      let data = _.pick(props, ["store", "branch", "machine"]);
      data.date = this.date;
      data.payload = _.pick(props.current, [type]);
      await this.axios.post(`/dashboard/machine`, data);
    },
    format(val) {
      return new Intl.NumberFormat().format(val);
    },
    warnBg(val) {
      return val == 0 ? "error-bg" : "";
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep tr.error-row {
  background: #ff5252 !important;
  color: #fff;
  font-weight: bold;
}
::v-deep .error-bg {
  background: #ff5252 !important;
  color: #fff;
  font-weight: bold;
}
</style>
