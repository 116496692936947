var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"page-title"},[_vm._v("兌換記錄")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-datetime-picker',{attrs:{"textFieldProps":{ outlined: true, dense: true, hideDetails: true },"datePickerProps":{
          locale: 'zh-tw',
          'no-title': true,
          scrollable: true,
        },"timePickerProps":{ locale: 'zh-tw' },"label":"開始時間","okText":"確定","clearText":"清除"},model:{value:(_vm.datetime[0]),callback:function ($$v) {_vm.$set(_vm.datetime, 0, $$v)},expression:"datetime[0]"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-clock-outline")])],1)],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-datetime-picker',{attrs:{"textFieldProps":{ outlined: true, dense: true, hideDetails: true },"datePickerProps":{
          locale: 'zh-tw',
          'no-title': true,
          scrollable: true,
        },"timePickerProps":{ locale: 'zh-tw' },"label":"結束時間","okText":"確定","clearText":"清除"},model:{value:(_vm.datetime[1]),callback:function ($$v) {_vm.$set(_vm.datetime, 1, $$v)},expression:"datetime[1]"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-clock-outline")])],1)],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"關鍵字","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"keyword"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"店點","outlined":"","dense":"","hide-details":"","clearable":"","items":_vm.storeList},model:{value:(_vm.storeID),callback:function ($$v) {_vm.storeID=$$v},expression:"storeID"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.search}},[_vm._v("查詢")])],1)],1),(_vm.result)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.ppRecord,"mobile-breakpoint":0,"item-key":"index","hide-default-footer":"","footer-props":{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [-1],
        }},scopedSlots:_vm._u([{key:"item.point",fn:function({ item }){return [_c('td',[_c('div',{class:item.point > 0 ? 'red--text' : 'green--text'},[_vm._v(" "+_vm._s(item.point)+" ")])])]}}],null,false,2130127374)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }