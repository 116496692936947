<template>
  <v-app>
    <v-main>
      <keep-alive :include="['Ichiban', 'StageIchiban', 'dev']">
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
html {
  overscroll-behavior: none;
}
.pointer {
  cursor: pointer;
}
</style>
