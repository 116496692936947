<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      換抽紀錄
    </h3>
    <v-row align="center" justify="space-between" justify-sm="start">
      <v-col cols="12" sm="6" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="searchQuery.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              label="日期區間"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="searchQuery.date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(searchQuery.date)"
            >
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="8" sm="4" md="2">
        <v-autocomplete
          label="店點"
          v-model="searchQuery.branch"
          :items="branches"
          outlined
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col align-self="end" cols="auto" sm="auto" md="1">
        <v-btn color="primary" @click="search">
          <span class="d-none d-md-inline">查詢</span>
          <v-icon class="d-md-none">mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字 (商品、會員)"
          v-model="filter.keyword"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          label="分組依據"
          v-model="groupKey"
          :items="[
            { text: '機台', value: 'machine' },
            { text: '換抽套編號', value: 'setTag' },
          ]"
          outlined
          dense
          hide-details
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :items="filterData"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100],
            showCurrentPage: true,
            showFirstLastPage: true,
          }"
          :group-by="groupKey"
        >
          <template v-slot:item.amount="{ item }">
            {{ formatPrice(item.amount) }}
          </template>
          <template v-slot:group.header="{ group, items }">
            <td :colspan="headers.length">
              <div class="pa-2 font-weight-bold">
                {{ group ? `機台 ${group}` : "未知套數" }}
                （ 總額：{{ formatPrice(getCostSum(items)) }}）
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import util from "@/mixins/util";

export default {
  name: "LotteryLog",
  mixins: [util],
  data: () => ({
    menu: false,
    data: [],
    searchQuery: {
      date: [],
      branch: "文賢",
    },
    filter: {
      keyword: "",
      branch: "",
    },
    branches: [
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
    ],
    groupKey: "machine",
    headers: [
      { text: "編號", value: "setTag", align: "center", sortable: false },
      { text: "商品名稱", value: "product.name", sortable: false },
      { text: "單價", value: "product.price", sortable: false },
      { text: "數量", value: "amount", sortable: false },
      { text: "會員", value: "userId", align: "center", sortable: false },
      { text: "店點", value: "branch", align: "center", sortable: false },
      { text: "機台", value: "machine", align: "center", sortable: false },
      { text: "兌換時間", value: "createdAt", align: "center" },
      // { text: "操作者", value: "user", align: "center", sortable: false },
    ],
  }),
  computed: {
    _: () => _,
    formattedDate() {
      const date = this.searchQuery.date;
      return date[0] && date[1]
        ? dayjs(date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(date[1]).format("YYYY-MM-DD")
        : "";
    },
    filterData() {
      return this.data.filter((item) => {
        if (this.filter.keyword) {
          const productName = item.product.name.toLowerCase();
          const user = item.userId.toLowerCase();
          const keyword = this.filter.keyword.toLowerCase();
          return productName.includes(keyword) || user.includes(keyword);
        }
        if (this.filter.branch) {
          return item.branch === this.filter.branch;
        }
        return true;
      });
    },
  },
  async created() {
    this.searchQuery.date[0] = dayjs().format("YYYY-MM-01");
    this.searchQuery.date[1] = dayjs().endOf("month").format("YYYY-MM-DD");
  },
  methods: {
    async search() {
      if (!this.searchQuery.date[0] || !this.searchQuery.date[1]) {
        this.$toast.warning("請選擇日期區間");
        return;
      }
      if (!this.searchQuery.branch) {
        this.$toast.warning("請選擇店點");
        return;
      }

      this.$vloading.show();
      let { data } = await this.axios.get(`/receivedAward`, {
        params: {
          dateRange: this.searchQuery.date,
          branch: this.searchQuery.branch,
        },
      });
      this.data = data;
      this.$vloading.hide();
    },
    getCostSum(items) {
      return _.sumBy(items, (item) => {
        if (!item.product.price) return 0;
        return item.product.price * item.amount;
      });
    },
    // async load() {
    //   await this.getReceivedAwards();
    // },
    // async getReceivedAwards() {
    //   let { data } = await this.axios.get(`/receivedAward`);
    //   this.data = data;
    //   this.branches = [
    //     ...new Set(data.map((item) => item.branch).filter((item) => !!item)),
    //   ];
    // },
  },
};
</script>
