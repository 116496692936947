<template>
  <v-container fluid>
    <h3 class="page-title">用戶列表</h3>
    <v-row align="center">
      <v-col cols="2">
        <v-text-field
          label="關鍵字"
          v-model.trim="keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="1">
        <!-- <v-btn color="primary" class="mr-2" @click="download">下載</v-btn> -->
        <v-btn color="primary" class="" @click="search">查詢</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="userRecord"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
        >
          <template v-slot:item.balance="{ item }">
            <td>
              <div>{{ formatPrice(item.balance) }}</div>
            </td>
          </template>
          <template v-slot:item.ticket="{ item }">
            <td>
              <div>{{ formatPrice(item.ticket) }}</div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";

export default {
  name: "BallUser",
  data: () => ({
    menu: false,
    result: null,
    keyword: null,
    headers: [
      { text: "序號", value: "index", sortable: false },
      { text: "會員", value: "userID", sortable: false },
      {
        text: "彩票",
        value: "ticket",
        sortable: true,
      },
      {
        text: "顆數",
        value: "balance",
        sortable: true,
      },
      {
        text: "註冊時間",
        value: "createdAt",
        sortable: false,
      },
    ],
  }),
  created() {
    this.search();
  },
  computed: {
    userRecord() {
      let result = this.result;
      if (this.keyword) {
        result = result.filter((row) => row.userID.includes(this.keyword));
      }
      return result;
    },
  },
  methods: {
    formatPrice(value) {
      return new Intl.NumberFormat().format(value || 0);
    },
    async search() {
      this.$vloading.show();

      let result = (await this.axios.get(`/dashboard/ballUser`)).data;
      this.result = result;
      this.$vloading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
