<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      兌換換抽獎品（櫃檯用）
      <v-badge bordered color="info" :content="cartCount" overlap>
        <v-btn clock color="primary" @click="openDialog"> 兌換清單 </v-btn>
      </v-badge>
    </h3>
    <v-row align="center">
      <v-col cols="6" sm="3">
        <v-select
          label="店點"
          hide-details
          v-model="branch"
          :items="[
            { text: '請選擇', value: '', disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
          ]"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="機台"
          v-model="machine"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels>
        <template v-for="(stall, index) in filterStalls">
          <v-expansion-panel v-if="stall && stall.awards?.length">
            <v-expansion-panel-header
              color="info"
              class="font-weight-bold white--text"
            >
              <template>
                {{ stall.machine }}
                <span v-if="hint[branch] && hint[branch][stall.machine]">
                  {{ hint[branch][stall.machine] ?? "" }}
                </span>
              </template>

              <template v-slot:actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <template
                  v-for="award of stall.awards.filter(
                    (award) => award.lottery !== 0
                  )"
                >
                  <v-list-item class="my-1" v-if="productMap[award.productId]">
                    <v-row align="center">
                      <v-col cols="auto">
                        {{ productMap[award.productId].name }}
                        <br />
                        <span class="caption">
                          抽數: {{ award.lottery }} \ 庫存:
                          {{ stockMap[award.productId]?.amount ?? 0 }}
                        </span>
                      </v-col>
                      <v-col cols="auto" class="ml-auto">
                        <v-text-field
                          :value="cart[`${award.productId}__${stall.machine}`]"
                          type="number"
                          dense
                          hide-details
                          outlined
                          class="input-width"
                          prepend-icon="mdi-minus"
                          append-outer-icon="mdi-plus"
                          @change="
                            update(
                              `${award.productId}__${stall.machine}`,
                              $event
                            )
                          "
                          @click:prepend="
                            minus(`${award.productId}__${stall.machine}`)
                          "
                          @click:append-outer="
                            plus(
                              `${award.productId}__${stall.machine}`,
                              award.lottery < stockMap[award.productId]?.amount
                                ? award.lottery
                                : stockMap[award.productId]?.amount
                            )
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-row>

    <v-dialog v-model="dialogList" @click:outside="stopScan" max-width="400">
      <v-card>
        <v-card-title>兌換清單</v-card-title>
        <v-card-text>
          <div
            v-for="(amount, id) of cart"
            class="text-subtitle-1"
            v-if="amount"
          >
            {{ productMap[id.split("__")[0]].name }} x
            {{ amount }}
          </div>
          <v-spacer></v-spacer>
          <div class="text-right text-subtitle-1 font-weight-bold">
            共 {{ cartCount }} 樣
          </div>

          <!-- <v-text-field
            class="my-1"
            outlined
            label="會員編號"
            dense
            hide-details
            v-model="user"
            append-icon="mdi-qrcode-scan"
            @click:append="activeScan = true"
          ></v-text-field> -->

          <!-- <QrcodeStream
            class="qrcode-scanner"
            ref="scanner"
            v-if="activeScan"
            @detect="onDetect"
          ></QrcodeStream> -->
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="clearCart" class="bt-text-large"
            >清空</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="confirm" class="bt-text-large"
            >確認</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import machineNum from "@/config/machineNum.json";
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "TradePoint",
  mixins: [util],
  components: {
    QrcodeStream,
  },
  data: () => ({
    dialogList: false,
    branch: "",
    products: [],
    productMap: {},
    stockMap: {},
    data: [],
    stalls: [],
    stallMap: {},
    cart: {},
    user: "",
    keyword: "",
    machine: "",
    activeScan: false,
    hint: {
      文賢: {
        16: "冰品台",
        38: "布瑞克",
      },
    },
  }),
  computed: {
    _: () => _,
    filterStalls() {
      let result = this.stalls;
      if (this.machine) {
        result = this.stalls.filter((stall) => stall.machine === this.machine);
      }
      return result;
    },
    // lotteryProducts() {
    //   const flattenRewards = this.stalls.reduce((pre, stall) => {
    //     const awards = stall.awards;
    //     if (awards) {
    //       return [...pre, ...awards];
    //     }
    //     return pre;
    //   }, []);
    //   const ids = _.uniqBy(flattenRewards, "productId").map((o) => o.productId);

    //   return ids
    //     .map((id) => this.productMap[id])
    //     .filter((product) => !!product);
    // },
    cartCount() {
      return _.sum(_.values(this.cart)) || "0";
    },
  },
  created() {
    this.branch = this.$store.state.branch;
    this.$nextTick(async () => {
      await this.getProducts();
      this.load();
    });
  },
  methods: {
    // async onDetect(val) {
    //   let decode = (await val).content;
    //   if (decode.length == 16) {
    //     this.user = decode;
    //     this.stopScan();
    //   }
    // },
    stopScan() {
      this.activeScan = false;
    },
    openDialog() {
      if (parseInt(this.cartCount)) this.dialogList = true;
    },
    clearCart() {
      this.cart = {};
      this.user = null;
      this.stopScan();
      this.dialogList = false;
    },
    async confirm() {
      // if (!this.user) {
      //   this.$toast.error("請輸入會員編號");
      //   return;
      // }

      try {
        const cart = Object.entries(this.cart).filter(
          ([id, amount]) => !!id && !!amount
        );
        //todo transaction 扣庫存
        const transactions = cart.map(([id, amount]) => {
          const productId = id.split("__")[0];
          const machine = id.split("__")[1];
          return this.axios.post(`/transaction`, {
            from: this.branch,
            type: "出貨",
            productId,
            amount,
          });
        });

        //todo 新增兌換紀錄
        const trades = cart.map(([id, amount]) => {
          const productId = id.split("__")[0];
          const machine = id.split("__")[1];
          return this.axios.post(`/receivedAward`, {
            branch: this.branch,
            userId: "現場兌換",
            product: this.productMap[productId],
            amount,
            machine,
            setTag: this.stallMap[machine].setTag,
          });
        });

        //todo update 換抽狀態
        const updateLottery = cart.map(([id, amount]) => {
          const productId = id.split("__")[0];
          const machine = id.split("__")[1];
          const stall = this.stallMap[machine];
          const originAward = stall.awards.find(
            (award) => award.productId === productId
          );
          return this.axios.put(`/stall/${stall._id}/update-award`, {
            productId,
            newAward: { ...originAward, lottery: originAward.lottery - amount },
          });
        });

        await Promise.all([...transactions, trades, ...updateLottery]);

        this.$toast.success("兌換成功");
        this.clearCart();
        this.load();
      } catch (error) {
        console.log(error);
        this.$toast.error("兌換失敗");
      }
    },
    update(productId, value) {
      value = parseInt(value);
      this.$set(this.cart, productId, value);
    },
    minus(id) {
      if (this.cart[id] > 0) {
        this.cart[id]--;
      }
    },
    plus(id, max) {
      if (!this.cart[id]) {
        this.$set(this.cart, id, 0);
      }
      if (this.cart[id] + 1 > max) {
        this.$toast.warning("兌換量不得大於獎品數或庫存");
        return;
      }
      this.cart[id]++;
    },
    async getProducts() {
      const { data } = await this.axios.get(`/product`);
      this.products = data.filter((product) => product.name);
      this.productMap = _.keyBy(this.products, "_id");
    },
    async getStall() {
      this.$vloading.show();

      const { data } = await this.axios.get(`/stall?branch=${this.branch}`);
      this.stalls = data;
      this.stallMap = _.keyBy(data, "machine");

      this.$vloading.hide();
      // get array from 1 to 67
      // let stall = machineNum[this.branch];

      // stall = stall.map((item) => {
      //   let product_id =
      //     _.find(data, { branch: this.branch, machine: item.toString() })
      //       ?.productId || "";

      //   return {
      //     branch: this.branch,
      //     machine: item.toString(),
      //     product: product_id ? this.products[product_id] : null,
      //     group: parseInt(item / 10),
      //   };
      // });

      // this.data = stall;
    },
    async getStock() {
      const { data } = await this.axios.get(`/stock`, {
        params: {
          branch: this.branch,
        },
      });
      this.stockMap = _.keyBy(data, "productId");
    },
    async load() {
      if (!this.branch) return;
      this.cart = {};
      await Promise.all([this.getStall(), this.getStock()]);
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
.qrcode-scanner {
  margin-top: 20px;
  border: 3px solid rgb(229, 5, 5);
  border-radius: 5px;
}
.input-width {
  width: 110px;

  ::v-deep .v-input__slot {
    padding: 5px !important;
    input {
      text-align: center;
    }
  }
}

::v-deep .v-expansion-panel-header--active {
  min-height: 48px;
}
</style>
