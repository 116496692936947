<template>
  <v-container fluid>
    <h3 class="page-title">{{ type === "point" ? "出點記錄" : "出貨紀錄" }}</h3>
    <v-row align="center">
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              label="日期區間"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            no-title
            scrollable
            locale="zh-tw"
            :min="minDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="關鍵字"
          v-model.trim="keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          label="店點"
          v-model="branch"
          outlined
          dense
          hide-details
          clearable
          :items="storeList"
        >
        </v-select>
      </v-col>
      <v-col cols="1">
        <!-- <v-btn color="primary" class="mr-2" @click="download">下載</v-btn> -->
        <v-btn color="primary" class="" @click="search">查詢</v-btn>
      </v-col>
      <v-col class="ml-auto text-right font-weight-bold">
        <span>總計: {{ formatPrice(sum) }} 點</span>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ppRecord"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
        >
          <template v-slot:item.point="{ item }">
            <td>
              <div class="font-weight-bold">
                {{ item.point }}
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";

export default {
  name: "NewPointLog",
  data: () => ({
    date: [],
    minDate: "2024-02-16",
    menu: false,
    result: [],
    action: null,
    keyword: null,
    branch: null,
    storeList: [
      { text: "全部", value: null },
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
    ],
  }),
  created() {
    this.date[0] = dayjs().format("YYYY-MM-01");
    this.date[1] = dayjs().endOf("month").format("YYYY-MM-DD");
  },
  computed: {
    type() {
      return this.$route.params.type ?? "point";
    },
    sum() {
      return this.result
        ? _.sumBy(this.ppRecord, (o) => Number(o.pointSum))
        : 0;
    },
    headers() {
      let headers = [
        { text: "商品名稱", value: "name" },
        { text: "點數", value: "point", align: "center" },
        { text: "數量", value: "count", align: "center" },
        {
          text: "點數總和",
          value: "pointSum",
          sortable: false,
          align: "right",
        },
      ];

      // 只有出點紀錄＋選擇單一店點的情況才顯示機台
      if (this.type === "point" && this.branch) {
        headers = [
          { text: "機台", value: "machine", align: "center" },
          ...headers,
        ];
      }

      return headers;
    },
    ppRecord() {
      let result = this.result;
      if (this.branch) {
        result = result.filter((row) => row.branch == this.branch);
      }
      result = result.map((o) => o.payload);
      result = _.flatten(result);

      let pp = [];

      if (this.type === "point" && this.branch) {
        _.each(
          _.groupBy(result, (item) => `${item.machine}-${item.product._id}`),
          (arr, id) => {
            const product = arr[0].product;
            const machine = arr[0].machine;
            let count = 0;
            let pointSum = 0;

            arr.forEach((exchange) => {
              count += this.convertNumber(exchange.amount);
              pointSum +=
                this.convertNumber(exchange.amount) *
                this.convertNumber(exchange.product.points);
            });
            pp.push({
              _id: id,
              machine,
              name: product.name,
              point: product.points,
              count,
              pointSum,
            });
          }
        );
      } else {
        _.each(_.groupBy(result, "product._id"), (arr, productId) => {
          const product = arr[0].product;
          let count = 0;
          let pointSum = 0;

          arr.forEach((exchange) => {
            count += this.convertNumber(exchange.amount);
            pointSum +=
              this.convertNumber(exchange.amount) *
              this.convertNumber(
                exchange.product.points || product.plans[0].amount
              );
          });
          pp.push({
            _id: productId,
            name: product.name,
            point: product.points || product.plans[0].amount,
            count,
            pointSum,
          });
        });
      }

      if (this.keyword) {
        pp = pp.filter((row) =>
          row.name.toUpperCase().includes(this.keyword.toUpperCase())
        );
      }

      pp = _.sortBy(pp, "_id");
      // pp = pp.filter((o) => o.商品名稱 != "undefined" && o.點數 != 0);
      return pp;
    },
    formattedDate() {
      return this.date[0] && this.date[1]
        ? dayjs(this.date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(this.date[1]).format("YYYY-MM-DD")
        : "";
    },
  },
  methods: {
    formatPrice(value) {
      return new Intl.NumberFormat().format(value);
    },
    convertNumber(val) {
      if (Number.isNaN(Number(val))) {
        return 0;
      }
      return Number(val);
    },
    async search() {
      if (!this.date) {
        return false;
      }

      if (
        dayjs(this.date[0]).isBefore(this.minDate) ||
        dayjs(this.date[1]).isBefore(this.minDate)
      ) {
        return this.$toast.error(
          `${dayjs(this.minDate).format("YYYY/MM/DD")} 之前的記錄無法以此頁查詢`
        );
      }

      this.$vloading.show();

      let { data } = await this.axios.post(`/dashboard/pointLog`, {
        date: this.date,
      });

      // 過濾掉儲存點數的 log
      // 測試站原儲存點數不帶 payload 和 product
      const logs = data
        .filter((log) => log.store !== "undefined")
        .map((log) => {
          if (Array.isArray(log.payload)) {
            return log;
          }
          return { ...log, payload: [log.payload] };
        })
        .filter((log) => {
          return (
            !!log.payload[0] &&
            log.type !== "儲存點數" &&
            log.payload[0].product.name !== "現金"
          );
        });

      if (this.type === "point") {
        this.result = logs.filter((log) => log.point > 0);
      } else {
        this.result = logs.filter((log) => log.point < 0);
      }
      this.$vloading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
