import { render, staticRenderFns } from "./BallUser.vue?vue&type=template&id=5238e6d8&scoped=true&"
import script from "./BallUser.vue?vue&type=script&lang=js&"
export * from "./BallUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5238e6d8",
  null
  
)

export default component.exports