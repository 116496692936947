<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      兌換商品
      <v-badge bordered color="info" :content="cartCount" overlap>
        <v-btn clock color="primary" @click="openDialog"> 兌換清單 </v-btn>
      </v-badge>
    </h3>
    <v-row align="center">
      <v-col cols="6" sm="3">
        <v-select
          label="店點"
          hide-details
          v-model="branch"
          :items="[
            { text: '請選擇', value: '', disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
          ]"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(products, category) in categorys"
          :key="category"
        >
          <v-expansion-panel-header
            color="info"
            class="font-weight-bold white--text"
          >
            {{ category }}
            <template v-slot:actions>
              <v-icon color="white"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <template v-for="product of products">
                <div class="my-2">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      {{ product.points ? `[${product.points}] ` : "" }}
                      {{ product.name }}
                      <br />
                      庫存: {{ product.stock }}
                    </v-col>
                    <v-col cols="auto" class="ml-auto">
                      <v-text-field
                        :disabled="!product.stock"
                        :value="cart[product._id]"
                        type="number"
                        dense
                        hide-details
                        outlined
                        class="input-width"
                        prepend-icon="mdi-minus"
                        append-outer-icon="mdi-plus"
                        @change="update(product._id, $event)"
                        @click:prepend="minus(product._id)"
                        @click:append-outer="plus(product._id)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-dialog v-model="dialogList" @click:outside="stopScan">
      <v-card>
        <v-card-title>兌換清單</v-card-title>
        <v-card-text>
          <div
            v-for="(amount, pid) of cart"
            class="text-subtitle-1"
            v-if="amount"
          >
            [ {{ mapProducts[pid].points }} ] {{ mapProducts[pid].name }} x
            {{ amount }}
          </div>
          <v-spacer></v-spacer>
          <div class="text-right text-subtitle-1 font-weight-bold">
            兌換 {{ cartCount }} 樣
          </div>
          <div class="text-right text-subtitle-1 font-weight-bold">
            需要 {{ cartPoint }} 點
          </div>

          <v-text-field
            class="my-1"
            outlined
            label="會員編號"
            dense
            hide-details
            v-model="user"
            append-icon="mdi-qrcode-scan"
            @click:append="activeScan = true"
          ></v-text-field>

          <QrcodeStream
            class="qrcode-scanner"
            ref="scanner"
            v-if="activeScan"
            @detect="onDetect"
          ></QrcodeStream>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="clearCart" class="bt-text-large"
            >清空</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="confirm" class="bt-text-large"
            >確認</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "TradeProduct",
  mixins: [util],
  components: {
    QrcodeStream,
  },
  data: () => ({
    dialogList: false,
    branch: "",
    products: {},
    data: [],
    cart: {},
    user: "",
    keyword: "",
    activeScan: false,
  }),
  computed: {
    _: () => _,
    filterProducts() {
      let products = this.products;
      if (this.keyword) {
        products = _.filter(products, (product) => {
          return (
            product.name &&
            product.name.toLowerCase().includes(this.keyword.toLowerCase())
          );
        });
      }
      return products;
    },
    mapProducts() {
      return _.mapKeys(this.products, "_id");
    },
    categorys() {
      return _.groupBy(this.filterProducts, "category");
    },
    cartCount() {
      return _.sum(_.values(this.cart)) || "0";
    },
    cartPoint() {
      return (
        _.sum(
          _.map(
            this.cart,
            (amount, pid) => amount * this.mapProducts[pid].points
          )
        ) || 0
      );
    },
  },
  async created() {
    this.branch = this.$store.state.branch;
  },
  methods: {
    update(productId, value) {
      value = parseInt(value);
      this.$set(this.cart, productId, value);
    },
    async onDetect(val) {
      let decode = (await val).content;
      if (decode.length == 16) {
        this.user = decode;
        this.stopScan();
      }
    },
    stopScan() {
      this.activeScan = false;
    },
    openDialog() {
      if (parseInt(this.cartCount)) this.dialogList = true;
    },
    clearCart() {
      this.cart = {};
      this.user = null;
      this.stopScan();
      this.dialogList = false;
    },
    async confirm() {
      if (!this.user) {
        this.$toast.error("請輸入會員編號");
        return;
      }
      try {
        _.each(this.cart, (amount, pid) => {
          if (amount < 0) {
            throw `${this.mapProducts[pid].name} 兌換數量不得小於 0`;
          }
          if (amount > this.mapProducts[pid].stock) {
            throw `${this.mapProducts[pid].name} 庫存不足，請重新確認`;
          }
        });
      } catch (error) {
        this.$toast.error(error);
        return;
      }

      try {
        // 出貨
        let promises = _.map(this.cart, (amount, productId) =>
          this.axios.post(`/transaction`, {
            type: "出貨",
            from: this.branch,
            to: this.user,
            productId,
            amount,
          })
        );
        await Promise.all(promises);

        await this.axios.post(`/dashboard/tradePoint`, {
          branch: this.branch,
          userID: this.user,
          point: this.cartPoint * -1,
          payload: _.map(this.cart, (amount, productId) => {
            return {
              product: this.mapProducts[productId],
              amount: amount,
            };
          }),
        });

        // 記錄交易
        this.$toast.success("兌換成功");
        this.clearCart();
        this.load();
      } catch (error) {
        console.log(error);
        this.$toast.error("兌換失敗");
      }
    },
    minus(id) {
      if (this.cart[id] > 0) {
        this.cart[id]--;
      }
    },
    plus(id) {
      if (!this.cart[id]) {
        this.$set(this.cart, id, 0);
      }
      this.cart[id]++;
    },

    async load() {
      if (!this.branch) return;

      let res = await this.axios.get(`/product?type=兌換商品`);
      let products = _.filter(
        res.data,
        (product) => product.points && product.isAvailable
      );

      res = await this.axios.get(`/stock`, {
        params: {
          branch: this.branch,
        },
      });
      let stocks = _.mapKeys(res.data, "productId");
      products = products.map((product) => ({
        ...product,
        productId: product._id,
        stock: stocks[product._id]?.amount || 0,
      }));
      this.products = products;
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.cart = {};
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
.qrcode-scanner {
  margin-top: 20px;
  border: 3px solid rgb(229, 5, 5);
  border-radius: 5px;
}
.input-width {
  width: 110px;

  ::v-deep .v-input__slot {
    padding: 5px !important;
    input {
      text-align: center;
    }
  }
}

::v-deep .v-expansion-panel-header--active {
  min-height: 48px;
}
</style>
