<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card outlined>
          <v-card-title>顆數作業</v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col class="col-12">
                <v-text-field
                  v-model="userID"
                  label="會員編號"
                  outlined
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0" justify="center">
              <v-col class="col-12">
                <div class="mt-16">
                  <v-row>
                    <v-col class="col-6 text-center">
                      <div class="text-h6 text-center mb-2">
                        目前彩票: {{ user ? user.ticket || 0 : 0 }} 張
                      </div>
                      <v-btn-toggle
                        mandatory
                        large
                        v-model.number="ticketOP"
                        :color="opColor(ticketOP)"
                      >
                        <v-btn large :value="-1" width="110px" height="50px">
                          <v-icon>mdi-minus</v-icon>
                          領出
                        </v-btn>
                        <v-btn large :value="1" width="110px" height="50px">
                          <v-icon>mdi-plus</v-icon>
                          存入
                        </v-btn>
                      </v-btn-toggle>
                      <v-text-field
                        v-model.number="ticket"
                        class="mt-4 input-value"
                        label="彩票"
                        outlined
                        hide-details
                        :readonly="isMobile"
                        @click="
                          showKeyboard = true;
                          keyboardTarget = 'ticket';
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col class="col-6 text-center">
                      <div class="text-h6 text-center mb-2">
                        目前顆數: {{ user ? user.balance || 0 : 0 }} 顆
                      </div>
                      <v-btn-toggle
                        mandatory
                        large
                        v-model.number="ballOP"
                        :color="opColor(ballOP)"
                      >
                        <v-btn large :value="-1" width="110px" height="50px">
                          <v-icon>mdi-minus</v-icon>
                          領出
                        </v-btn>
                        <v-btn large :value="1" width="110px" height="50px">
                          <v-icon>mdi-plus</v-icon>
                          存入
                        </v-btn>
                      </v-btn-toggle>
                      <v-text-field
                        v-model.number="ball"
                        class="mt-4 input-value"
                        label="顆數"
                        outlined
                        hide-details
                        clearable
                        :readonly="isMobile"
                        @click="
                          showKeyboard = true;
                          keyboardTarget = 'ball';
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="mt-4">
                  <v-btn class="float-left" color="error" large @click="reset"
                    >取消</v-btn
                  >
                  <v-btn
                    class="float-right"
                    color="success"
                    large
                    @click="confirmTrade"
                    >確認</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined>
          <v-card-title>現金作業</v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-btn-toggle
                multiple
                large
                v-model="deposit"
                :color="opColor(cashOP)"
              >
                <v-btn
                  v-for="n of [100, 200, 300, 500, 1000]"
                  height="56px"
                  :width="btnWidth + 'px'"
                  x-large
                  :value="n"
                  :key="n"
                  @click="countCash"
                >
                  {{ n }}</v-btn
                >
              </v-btn-toggle>
            </div>
            <div class="empty-block"></div>
            <div class="text-center mt-4">
              <v-btn-toggle
                mandatory
                large
                v-model.number="cashOP"
                :color="opColor(cashOP)"
              >
                <v-btn x-large :value="-1" width="150px" height="50px">
                  <v-icon>mdi-minus</v-icon>
                  領出
                </v-btn>
                <v-btn x-large :value="1" width="150px" height="50px">
                  <v-icon>mdi-plus</v-icon>
                  存入
                </v-btn>
              </v-btn-toggle>
            </div>
            <div>
              <v-text-field
                v-model.number="cash"
                class="mt-4 input-value"
                label="金額"
                outlined
                hide-details
                clearable
                :readonly="isMobile"
                @click="
                  deposit = [];
                  showKeyboard = true;
                  keyboardTarget = 'cash';
                "
              ></v-text-field>
            </div>
            <div class="mt-4">
              <v-btn class="float-left" color="error" large @click="reset"
                >取消</v-btn
              >
              <v-btn
                class="float-right"
                color="success"
                large
                @click="confirmCash"
                >確認</v-btn
              >
            </div>
            <div style="clear: both"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <NumberKeyboard
      v-model="showKeyboard"
      @delete="deleteNum"
      @keyDown="plusNum"
      @confirm="confirmNum"
      :number="numArr.join('')"
    ></NumberKeyboard>
    <v-overlay
      :value="showKeyboard"
      :opacity="0.8"
      z-index="998"
      @click.stop.prevent="confirmNum"
    >
    </v-overlay>
  </div>
</template>

<script>
import NumberKeyboard from "@/components/number-keyboard/";
import barcodeReader from "@wrurik/barcode-scanner";
import dayjs from "dayjs";
import util from "@/mixins/util";
import _ from "lodash";
export default {
  name: "Ball",
  mixins: [util],
  components: {
    NumberKeyboard,
  },
  data: () => ({
    storeID: "成功",
    showKeyboard: false,
    userID: "",
    user: null,
    cancelScan: null,
    numArr: [],
    cashOP: 1,
    ticketOP: 1,
    ballOP: 1,
    ball: 0,
    ticket: 0,
    cash: 0,
    deposit: [],
    keyboardTarget: null,
  }),
  async created() {
    this.cancelScan = barcodeReader.onScan(this.scanHandler, {
      scanDuration: 500,
    });
  },
  beforeDestroy() {
    this.cancelScan();
  },
  computed: {
    btnWidth() {
      return (window.innerWidth / 2 - 32 - 32) / 5;
    },
    num() {
      return parseInt(this.numArr.join(""));
    },
  },
  methods: {
    countCash() {
      this.$nextTick(() => {
        this.cash = _.sum(this.deposit);
      });
    },
    opColor(op) {
      return op == 1 ? "success" : "error";
    },
    reset() {
      this.userID = null;
      this.user = null;
      this.ball = 0;
      this.ticket = 0;
      this.cash = 0;
      this.numArr = [];
      this.deposit = [];
      this.cashOP = 1;
      this.ticketOP = 1;
      this.ballOP = 1;
    },
    async load() {
      this.$vloading.show();
    },
    plus(val) {
      return val + 1;
    },
    minus(val) {
      return val <= 0 ? 0 : val - 1;
    },
    deleteNum() {
      this.numArr = [];
    },
    plusNum(val) {
      this.numArr.push(val);
    },
    confirmNum() {
      this[this.keyboardTarget] = this.num ? this.num : 0;
      this.showKeyboard = false;
      this.keyboardTarget = null;
    },
    scanHandler(value, e) {
      if (!value) return;
      this.userID = value;
    },
    // async printMessage(message) {
    //   try {
    //     await this.axios.post(
    //       "http://localhost:3007/print",
    //       {
    //         message,
    //       },
    //       {
    //         alone: true,
    //       }
    //     );
    //   } catch (error) {
    //     this.$toast.error("印表機錯誤！");
    //   }
    // },
    async getUser() {
      try {
        let { data } = await this.axios.get(`/users/${this.userID}`);
        if (!data) throw new Error();
        this.user = data;
      } catch (error) {
        this.$toast.error("查無此會員！");
      }
    },
    async confirmTrade() {
      if (!this.userID) {
        await this.$dialog.warning({
          text: "請掃描會員編號！",
        });
        return;
      }

      if (this.ballOP == -1 && this.ball > this.user?.balance) {
        await this.$dialog.warning({
          text: "顆數不足！",
        });
        return;
      }
      if (this.ticketOP == -1 && this.ticket > this.user?.ticket) {
        await this.$dialog.warning({
          text: "彩票不足！",
        });
        return;
      }

      try {
        if (this.ball) {
          await this.axios.post("/dashboard/tradeBall", {
            userID: this.userID,
            type: "point",
            value: this.ball * this.ballOP,
            storeID: this.storeID,
          });
          this.$toast.success(
            `${this.ballOP == -1 ? "領出" : "存入"} ${this.ball} 顆彈珠完成！`
          );
        }
        if (this.ticket) {
          await this.axios.post("/dashboard/tradeBall", {
            userID: this.userID,
            type: "ticket",
            value: this.ticket * this.ticketOP,
            storeID: this.storeID,
          });
          this.$toast.success(
            `${this.ticketOP == -1 ? "領出" : "存入"} ${
              this.ticket
            } 張彩票完成！`
          );
        }

        // await this.printMessage([
        //   `會員 ${this.userID}`,
        //   `${dayjs().format("YYYY-MM-DD HH:mm:ss")} ${
        //     this.op == -1 ? "兌出" : "存入"
        //   } ${this.value} ${type}\n`,
        // ]);
        this.reset();
      } catch (error) {
        console.log(error);
        this.$toast.error("兌換失敗，請聯繫管理者！");
      }
    },
    async confirmCash() {
      if (!this.cash) {
        this.$toast.error("請輸入金額！");
        return;
      }

      try {
        await this.axios.post("/dashboard/tradeCash", {
          value: this.cash * this.cashOP,
        });
        this.$toast.success(
          `${this.cashOP == -1 ? "領出" : "存入"} ${this.cash} 現金完成！`
        );
        // await this.printMessage([
        //   `${dayjs().format("YYYY-MM-DD HH:mm:ss")}`,
        //   `${this.$store.state.user.帳號}${
        //     this.cashOP == -1 ? "領出" : "存入"
        //   } ${this.cash} 元\n`,
        // ]);
        this.reset();
      } catch (error) {
        console.log(error);
        this.$toast.error("出納失敗，請聯繫管理者！");
      }
    },
  },
  watch: {
    showKeyboard(val) {
      if (!val) {
        this.numArr = [];
        this.updatedProductIndex = null;
        this.updatedOptionIndex = null;
      }
    },
    userID(val) {
      if (val && val.length == 16) {
        this.getUser();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.min-height {
  min-height: 100px;
}
.header {
  display: flex;
  align-items: center;
  //   position: fixed;
  height: 54px;
  width: 100%;
  padding: 0 10px;
  background-color: #fff;
  z-index: 10;
}
.item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0px;
}

.item {
  width: 120px;
  padding: 10px;
  margin: 5px;
  background-color: #d2e7fa1a;
  font-weight: 500;
  font-size: 12px;
}

.trade-wrapper {
  width: inherit;
  padding: 10px;
  position: sticky;
  top: 48px;
}

.choose-dialog {
}

.input-value ::v-deep input {
  text-align: center;
  margin-left: 28px;
  font-size: 26px;
}
.empty-block {
  height: 100px;
}
</style>
