import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
// import "sweetalert2/dist/sweetalert2.min.css";
import "@sweetalert2/theme-dark/dark.min.css";
import "@/assets/scss/custom-sweetalert.scss";

Vue.use(VueSweetalert2, {
  confirmButtonColor: "#00bcd4",
  customClass: {
    container: "my-container",
    popup: "pop-up",
  },
});

export default new VueSweetalert2();
