<template>
  <v-app>
    <div class="refresh-icon">
      <v-icon @click="hardreload">mdi-refresh</v-icon>
    </div>

    <v-app-bar-nav-icon @click.stop="drawerState = !drawerState">
    </v-app-bar-nav-icon>

    <v-navigation-drawer
      style="z-index: 100 !important"
      v-model="drawerState"
      absolute
      color="#0F4C81"
      dark
      hide-overlay
      mobile-breakpoint="30000"
    >
      <div class="d-flex justify-center mt-2">
        <v-img
          @click="$router.push('/dashboard')"
          class="pointer"
          width="100px"
          height="100px"
          contain
          :src="`../images/logo.png`"
        ></v-img>
      </div>
      <div class="white--text text-center text-h6 mb-2">集點系統</div>
      <v-list nav dense>
        <v-list-item-group>
          <template v-for="item of aclMenu">
            <v-list-item
              v-if="!item.children"
              @click="doAction(item.key)"
              active-class="white--text text--accent-4"
            >
              <v-list-item-title class="text-body-1 py-2 my-2">
                <div>{{ item.title }}</div>
                <span class="caption">{{ item.caption }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-group v-else>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.title"
                    class="text-body-1 py-1 my-1"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="child in item.children"
                :key="child.title"
                @click="doAction(child.key)"
                active-class="white--text text--accent-4"
                class="pl-6"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="child.title"
                    class="text-body-1"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list-item-group>
      </v-list>

      <!-- <v-list nav dense>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="item of aclMenu"
            :key="item.key"
            @click="doAction(item.key)"
          >
            <v-list-item-title class="text-body-1 py-2 my-2">
              <div>{{ item.title }}</div>
              <span class="caption">{{ item.caption }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list> -->
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const defaultDrawer = false;
import ls from "expired-localstorage";
import util from "@/mixins/util";
export default {
  name: "DashboardTemplate",
  mixins: [util],
  data: () => ({
    selectedItem: 0,
    selected: "",
    menu: [
      {
        title: "兌換點數",
        key: "tradePoint",
        acl: ["管理者", "主管", "店長", "店員"],
      },
      {
        title: "兌換商品",
        key: "tradeProduct",
        acl: ["管理者", "主管", "店長", "店員"],
      },
      {
        title: "兌換換抽獎品（櫃檯用）",
        caption: "含冰品及布瑞克",
        key: "tradeLottery",
        acl: ["管理者", "主管", "店長", "店員"],
      },
      {
        title: "機台換抽管理",
        key: "lottery",
        acl: ["管理者", "主管", "店長", "店員"],
      },
      {
        title: "一番賞",
        children: [
          {
            title: "購買一番賞",
            key: "tradeIchiban",
            acl: ["管理者", "主管", "店長", "店員", "友愛"],
          },
          {
            title: "一番賞管理 *",
            key: "ichiban",
            acl: ["管理者", "主管"],
          },
          {
            title: "格主一番賞管理 *",
            key: "ichibanPending",
            acl: ["管理者", "主管"],
          },
          {
            title: "格主庫存管理 *",
            key: "ichibanOwnerStock",
            acl: ["管理者", "主管"],
          },
          {
            title: "一番賞鎖定管理(延長鎖定) *",
            key: "ichibanLock",
            acl: ["管理者", "主管"],
          },
          {
            title: "一番賞交易紀錄 *+",
            key: "ichibanRecord",
            acl: ["管理者", "主管", "店長"],
          },
          {
            title: "線上一番賞訂單管理",
            key: "ichibanOrder",
            acl: ["管理者", "主管", "店長", "店員"],
          },
          {
            title: "線上一番賞公告管理 *",
            key: "ichibanBanner",
            acl: ["管理者", "主管"],
          },
          {
            title: "線上一番賞類別管理 *",
            key: "ichibanCategory",
            acl: ["管理者", "主管"],
          },
          {
            title: "線上一番賞圖片設定 *",
            key: "ichibanConfig",
            acl: ["管理者", "主管"],
          },
        ],
      },
      {
        title: "機台管理",
        key: "stall",
        acl: ["管理者", "主管", "店長", "店員"],
      },
      {
        title: "訂單管理",
        key: "order",
        acl: ["管理者", "主管", "店長", "店員"],
      },
      {
        title: "賣貨紀錄",
        key: "trade",
        caption: "紀錄盲盒販售",
        acl: ["管理者", "主管", "店長", "店員", "友愛"],
      },
      {
        title: "商品管理 *+",
        key: "product",
        acl: ["管理者", "主管", "採購", "店長", "友愛"],
      },
      {
        title: "會員列表",
        caption: "含儲值點數",
        key: "users",
        acl: ["管理者", "主管"],
      },
      {
        title: "請購 | 採購",
        children: [
          {
            title: "請購單",
            key: "purchase",
            acl: ["管理者", "主管", "店長", "店員", "採購", "友愛"],
          },
          {
            title: "歷史請購單 *+",
            key: "purchaseHistory",
            acl: ["管理者", "主管", "店長", "採購"],
          },
          {
            title: "採購單",
            key: "purchaseOrder",
            acl: ["管理者", "主管", "店長", "店員", "採購"],
          },
          {
            title: "歷史採購單 *+",
            key: "purchaseOrderHistory",
            acl: ["管理者", "主管", "店長", "採購"],
          },
        ],
      },
      {
        title: "庫存 | 盤點",
        children: [
          {
            title: "庫存管理",
            key: "stock",
            acl: ["管理者", "主管", "店長", "店員", "採購"],
          },
          {
            title: "庫存管理（友愛）",
            key: "stock/uij",
            acl: ["管理者", "主管", "店長", "採購", "友愛"],
          },
          {
            title: "庫存管理（奇美）",
            key: "stock/chimei",
            acl: ["管理者", "主管", "店長", "採購"],
          },
          {
            title: "庫存總覽 *+",
            key: "stockAll",
            acl: ["管理者", "主管", "店長", "採購"],
          },
          {
            title: "庫存異動紀錄 *",
            key: "transactionHistory",
            acl: ["管理者", "主管"],
          },
          {
            title: "盤點管理 *+",
            key: "inventory",
            acl: ["管理者", "主管", "店長", "採購"],
          },
        ],
      },
      {
        title: "帳表 | 收支",
        children: [
          {
            title: "收支明細 *+",
            key: "payment",
            acl: ["管理者", "主管", "店長"],
          },
          {
            title: "當日櫃檯帳表",
            key: "incomeStatement",
            acl: ["管理者", "主管", "店長", "店員", "友愛"],
          },
          {
            title: "櫃檯帳表管理 *+",
            key: "incomeStatementManage",
            acl: ["管理者", "主管", "店長"],
          },
          {
            title: "待付貨款 *+",
            key: "payable",
            acl: ["管理者", "主管", "店長"],
          },
          {
            title: "貨款紀錄與管理 *+",
            key: "payableLog",
            acl: ["管理者", "主管", "店長"],
          },
        ],
      },
      {
        title: "中控 | 檔期",
        children: [
          {
            title: "檔期管理 *",
            key: "period",
            acl: ["管理者", "主管"],
          },
          {
            title: "檔期盈虧 *",
            key: "periodProfit",
            acl: ["管理者", "主管"],
          },
          {
            title: "機台記錄 *+",
            key: "machine",
            acl: ["管理者", "主管", "店長"],
          },
          {
            title: "機台統計 *",
            key: "machineStatistic",
            acl: ["管理者", "主管"],
          },
        ],
      },
      {
        title: "各類兌換紀錄",
        children: [
          {
            title: "點數記錄",
            key: "pointLog",
            acl: ["管理者", "主管", "店長", "店員"],
          },
          {
            title: "出點紀錄 *",
            key: "pointExchangeLog/point",
            acl: ["管理者", "主管"],
          },
          {
            title: "出貨紀錄 *",
            key: "pointExchangeLog/product",
            acl: ["管理者", "主管"],
          },
          {
            title: "換抽紀錄 *",
            key: "lotteryLog",
            acl: ["管理者", "主管"],
          },
          {
            title: "店員兌換記錄 *",
            key: "clawSystemLog",
            acl: ["管理者", "主管"],
          },
        ],
      },
      {
        title: "櫃檯作業",
        key: "ball",
        acl: ["管理者", "彈珠台-店長"],
      },
      {
        title: "兌換記錄",
        key: "ballLog",
        acl: ["管理者", "彈珠台-店長"],
      },
      {
        title: "出納記錄",
        key: "ballCash",
        acl: ["管理者", "彈珠台-店長"],
      },
      {
        title: "用戶列表",
        key: "ballUser",
        acl: ["管理者", "彈珠台-店長"],
      },
      // {
      //   title: "出點記錄 (封存)",
      //   key: "ppLog",
      //   acl: ["管理者", "主管"],
      // },
      // {
      //   title: "兌換記錄 (封存)",
      //   key: "exchangeLog",
      //   acl: ["管理者", "主管"],
      // },
      {
        title: "系統記錄",
        key: "systemLog",
        acl: ["管理者"],
      },
      {
        title: "登出",
        key: "logout",
        acl: [],
      },
    ],
  }),
  async created() {
    await this.getMe();
    this.$store.commit("toggleDrawerState", defaultDrawer);
    let key = this.$route.name;
    this.selectedItem = _.findIndex(this.aclMenu, { key });
    this.selected = key;
  },
  computed: {
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        return this.$store.commit("toggleDrawerState", v);
      },
    },
    aclMenu() {
      let menu = [...this.menu];
      menu = menu.filter((item) => {
        if (item.children) {
          item.children = item.children.filter((child) => {
            return (
              child.acl.includes(this.$store.state.user.角色) ||
              child.specialCase?.includes(this.$store.state.user.帳號)
            );
          });
          if (item.children.length) return true;
          return false;
        }

        if (!item.acl.length) return true;
        return (
          item.acl.includes(this.$store.state.user.角色) ||
          item.specialCase?.includes(this.$store.state.user.帳號)
        );
      });
      return menu;
    },
  },
  methods: {
    hardreload() {
      location.reload(true);
    },
    doAction(key) {
      if (key == "logout") {
        ls.remove("token");
        this.$router.push(`/dashboard`);
      } else {
        this.$router.push(`/dashboard/${key}`);
      }
    },
  },
};
</script>
<style lang="scss">
.page-title {
  font-size: 18px;
  margin-bottom: 20px;
}
.refresh-icon {
  position: absolute;
  right: 4px;
  top: 4px;
}
.bt-text-large {
  font-size: 18px !important;
}
</style>
