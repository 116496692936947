<template>
  <v-container fluid>
    <h3 class="page-title">兌換記錄</h3>
    <v-row align="center">
      <v-col cols="2">
        <v-datetime-picker
          :textFieldProps="{ outlined: true, dense: true, hideDetails: true }"
          :datePickerProps="{
            locale: 'zh-tw',
            'no-title': true,
            scrollable: true,
          }"
          :timePickerProps="{ locale: 'zh-tw' }"
          label="開始時間"
          okText="確定"
          clearText="清除"
          v-model="datetime[0]"
        >
          <template slot="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template slot="timeIcon">
            <v-icon>mdi-clock-outline</v-icon>
          </template>
        </v-datetime-picker>
      </v-col>
      <v-col cols="2">
        <v-datetime-picker
          :textFieldProps="{ outlined: true, dense: true, hideDetails: true }"
          :datePickerProps="{
            locale: 'zh-tw',
            'no-title': true,
            scrollable: true,
          }"
          :timePickerProps="{ locale: 'zh-tw' }"
          label="結束時間"
          okText="確定"
          clearText="清除"
          v-model="datetime[1]"
        >
          <template slot="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template slot="timeIcon">
            <v-icon>mdi-clock-outline</v-icon>
          </template>
        </v-datetime-picker>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="關鍵字"
          v-model.trim="keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          label="店點"
          v-model="storeID"
          outlined
          dense
          hide-details
          clearable
          :items="storeList"
        >
        </v-select>
      </v-col>
      <v-col cols="1">
        <!-- <v-btn color="primary" class="mr-2" @click="download">下載</v-btn> -->
        <v-btn color="primary" class="" @click="search">查詢</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ppRecord"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
        >
          <template v-slot:item.point="{ item }">
            <td>
              <div :class="item.point > 0 ? 'red--text' : 'green--text'">
                {{ item.point }}
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";

export default {
  name: "ExchangeLog",
  data: () => ({
    datetime: [null, null],
    result: null,
    action: null,
    keyword: null,
    storeID: null,
    storeList: [
      { text: "全部", value: null },
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
    ],
    headers: [
      { text: "機台商品", value: "機台商品" },
      { text: "兌換商品", value: "兌換商品" },
      { text: "數量", value: "數量" },
      {
        text: "點數",
        value: "點數",
      },
    ],
  }),
  created() {
    this.datetime[0] = dayjs().format("YYYY-MM-01 06:00");
    this.datetime[1] = dayjs().add(1, "month").format("YYYY-MM-01 02:00");
  },
  computed: {
    ppRecord() {
      let result = this.result;
      if (this.storeID) {
        result = result.filter((row) => row.storeID == this.storeID);
      }
      result = result.map((o) => o.payload);
      result = _.flatten(result);
      result = _.filter(result, (o) => o.商品);

      _.each(result, (row) => {
        if (!row.商品.機台商品) {
          row.商品.機台商品 = row.商品.商品名稱;
          row.商品.商品名稱 = "點數";
        }
      });
      if (this.keyword) {
        result = result.filter(
          (row) =>
            row.商品?.商品名稱?.includes(this.keyword) ||
            row.商品?.機台商品?.includes(this.keyword)
        );
      }

      let pp = [];
      _.each(_.groupBy(result, "商品.機台商品"), (機台商品, 機台商品名稱) => {
        _.each(
          _.groupBy(機台商品, "商品.商品名稱"),
          (兌換商品, 兌換商品名稱) => {
            pp.push({
              order: parseInt(機台商品名稱),
              機台商品: 機台商品名稱,
              兌換商品: 兌換商品名稱,
              rows: 兌換商品,
              數量: _.sumBy(兌換商品, "數量"),
              點數: _.sumBy(兌換商品, "數量") * (兌換商品[0].商品.點數 || 0),
            });
          }
        );
      });

      pp = _.sortBy(pp, "order");
      // console.log(pp);
      // pp = pp.filter((o) => o.商品名稱 != "undefined" && o.點數 != 0);
      return pp;
    },
  },
  methods: {
    formatPrice(value) {
      return new Intl.NumberFormat().format(value);
    },
    async search() {
      if (!this.datetime[0] || !this.datetime[1]) {
        return false;
      }
      this.$vloading.show();

      let result = (
        await this.axios.post(`/dashboard/pointLog`, { date: this.datetime })
      ).data;
      this.result = result;
      this.$vloading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
