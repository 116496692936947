<template>
  <v-container fluid>
    <h3 class="page-title">出納記錄</h3>
    <v-row align="center">
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatedDate"
              label="日期區間"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="1">
        <!-- <v-btn color="primary" class="mr-2" @click="download">下載</v-btn> -->
        <v-btn color="primary" class="" @click="search">查詢</v-btn>
      </v-col>
      <v-col class="ml-auto text-right font-weight-bold">
        <span class="mr-8">出納小記: {{ formatPrice(total) }}</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-btn
          class="mr-2"
          small
          color="info"
          @click="date = datePickerArr.today"
        >
          本日
        </v-btn>
        <v-btn
          class="mr-2"
          small
          color="info"
          @click="date = datePickerArr.yesterday"
        >
          昨日
        </v-btn>
        <v-btn
          class="mr-2"
          small
          color="info"
          @click="date = datePickerArr.thisMonth"
        >
          本月
        </v-btn>
        <v-btn
          class="mr-2"
          small
          color="info"
          @click="date = datePickerArr.prevMonth"
        >
          上月
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ballRecord"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [20, 100, -1],
          }"
        >
          <template v-slot:item.value="{ item }">
            <td>
              <div :class="item.value < 0 ? 'red--text' : ''">
                {{ formatPrice(item.value) }}
              </div>
            </td>
          </template>
          <template v-slot:item.type="{ item }">
            <td>
              <div>
                {{ item.value > 0 ? "存入" : "領出" }}
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import util from "@/mixins/util";

export default {
  name: "BallCash",
  mixins: [util],
  data: () => ({
    date: [],
    menu: false,
    result: null,
    action: null,
    headers: [
      { text: "序號", value: "index", sortable: false },
      { text: "動作", value: "type", sortable: false },
      {
        text: "金額",
        value: "value",
        sortable: false,
      },
      {
        text: "執行時間",
        value: "createdAt",
        sortable: false,
      },
    ],
  }),
  created() {
    this.date[0] = dayjs().format("YYYY-MM-01");
    this.date[1] = dayjs().endOf("month").format("YYYY-MM-DD");
  },
  computed: {
    total() {
      let result = this.ballRecord;
      return _.sumBy(result, (row) => row.value);
    },
    ballRecord() {
      let result = this.result;

      return result;
    },
    formatedDate() {
      return this.date[0] && this.date[1]
        ? dayjs(this.date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(this.date[1]).format("YYYY-MM-DD")
        : "";
    },
  },
  methods: {
    formatPrice(value) {
      return new Intl.NumberFormat().format(value);
    },
    async search() {
      if (!this.date) {
        return false;
      }
      this.$vloading.show();

      let result = (
        await this.axios.post(`/dashboard/ballCash`, { date: this.date })
      ).data;
      this.result = result;
      this.$vloading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
