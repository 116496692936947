<template>
  <v-container fluid>
    <h3 class="page-title">出點記錄</h3>
    <v-row align="center">
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatedDate"
              label="日期區間"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            no-title
            scrollable
            locale="zh-tw"
            max="2024-02-15"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="關鍵字"
          v-model.trim="keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          label="店點"
          v-model="storeID"
          outlined
          dense
          hide-details
          clearable
          :items="storeList"
        >
        </v-select>
      </v-col>
      <v-col cols="1">
        <!-- <v-btn color="primary" class="mr-2" @click="download">下載</v-btn> -->
        <v-btn color="primary" class="" @click="search">查詢</v-btn>
      </v-col>
      <v-col class="ml-auto text-right">
        <span>總計: {{ formatPrice(sum) }} 點</span>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ppRecord"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
        >
          <template v-slot:item.point="{ item }">
            <td>
              <div :class="item.point > 0 ? 'red--text' : 'green--text'">
                {{ item.point }}
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";

export default {
  name: "PointLog",
  data: () => ({
    date: [],
    menu: false,
    result: null,
    action: null,
    keyword: null,
    storeID: null,
    storeList: [
      { text: "全部", value: null },
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
    ],
    headers: [
      { text: "商品名稱", value: "商品名稱" },
      { text: "數量", value: "數量" },
      {
        text: "點數",
        value: "點數",
      },
    ],
  }),
  created() {
    this.date[0] = dayjs().format("2024-02-01");
    this.date[1] = dayjs().format("2024-02-15");
  },
  computed: {
    sum() {
      return this.result ? _.sumBy(this.ppRecord, (o) => o.點數) : 0;
    },
    ppRecord() {
      let result = this.result;
      if (this.storeID) {
        result = result.filter((row) => row.storeID == this.storeID);
      }
      result = result.map((o) => o.payload);
      result = _.flatten(result);
      let pp = [];
      _.each(_.groupBy(result, "商品.商品名稱"), (arr, productName) => {
        let count = 0;
        let point = 0;
        _.each(arr, (o) => {
          if (o.商品) {
            count += o.數量;
            point += o.商品.點數 * o.數量;
          }
        });
        pp.push({
          order: parseInt(productName),
          商品名稱: productName,
          數量: count,
          點數: point,
        });
      });

      if (this.keyword) {
        pp = pp.filter((row) => row.商品名稱.includes(this.keyword));
      }

      pp = _.sortBy(pp, "order");
      pp = pp.filter((o) => o.商品名稱 != "undefined" && o.點數 != 0);
      return pp;
    },
    formatedDate() {
      return this.date[0] && this.date[1]
        ? dayjs(this.date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(this.date[1]).format("YYYY-MM-DD")
        : "";
    },
  },
  methods: {
    formatPrice(value) {
      return new Intl.NumberFormat().format(value);
    },
    async search() {
      if (!this.date) {
        return false;
      }
      this.$vloading.show();

      let result = (
        await this.axios.post(`/dashboard/pointLog`, { date: this.date })
      ).data;
      this.result = result;
      this.$vloading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
