import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import { LoadingPlugin } from "vuetify-loading-overlay";
import ls from "expired-localstorage";
import axios from "axios";
import { apiURL } from "../env.js";
import DatetimePicker from "vuetify-datetime-picker";
import VuetifyDialog from "vuetify-dialog";
import sweetalert2 from "./plugins/sweetalert.js";
import "vuetify-dialog/dist/vuetify-dialog.css";
import "vue-toastification/dist/index.css";

//共用樣式
import "@/assets/scss/util.scss";

// import VConsole from "vconsole";
// const vConsole = new VConsole({ theme: "dark" });

axios.interceptors.request.use(
  function (config) {
    let token = ls.get("token");
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    config.url = config.alone ? config.url : apiURL + config.url;
    // alert(JSON.stringify(config));
    return config;
  },
  function (error) {
    console.log(error.message);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          location.href = location.pathname.includes("/dashboard")
            ? "/dashboard"
            : "/";
          break;
        default:
          console.log(error.message);
      }
    }
    return Promise.reject(error);
  }
);
Vue.use(DatetimePicker);
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    waitForResult: true,
    title: "確認",
    icon: false,
    actions: {
      false: {
        text: "取消",
        color: "error",
      },
      true: {
        text: "確認",
        color: "primary",
      },
    },
  },
  prompt: {
    waitForResult: true,
    icon: false,
    actions: {
      false: {
        text: "取消",
        color: "error",
      },
      true: {
        text: "確認",
        color: "primary",
      },
    },
  },
  warning: {
    waitForResult: false,
    title: "注意",
    icon: false,
    actions: {
      false: "",
      true: {
        text: "關閉",
        color: "#333",
      },
    },
  },
});
Vue.use(Toast, {
  position: "bottom-center",
  timeout: 3000,
  pauseOnHover: false,
  hideProgressBar: false,
  bodyClassName: ["toast-text"],
});
Vue.use(LoadingPlugin, {
  spinnerProps: {
    color: "#0F4C81",
    width: "7",
    size: 70,
  },
  overlayProps: {
    "z-index": 9999,
  },
});

Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.ls = ls;

new Vue({
  router,
  store,
  vuetify,
  sweetalert2,
  render: (h) => h(App),
}).$mount("#app");
