<template>
  <v-container fluid>
    <h3 class="page-title">點數記錄</h3>
    <v-row align="center">
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatedDate"
              label="日期區間"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="關鍵字"
          v-model.trim="keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select
          label="店點"
          v-model="branch"
          outlined
          dense
          hide-details
          clearable
          :items="branchList"
        >
        </v-select>
      </v-col>
      <v-col cols="1">
        <!-- <v-btn color="primary" class="mr-2" @click="download">下載</v-btn> -->
        <v-btn color="primary" class="" @click="search">查詢</v-btn>
      </v-col>
      <v-col class="ml-auto text-right">
        <span class="mr-4">點數增加: {{ formatPrice(sum.plus) }}</span>
        <span class="mr-4">點數減少: {{ formatPrice(sum.minus) }}</span>
        <span>總異動: {{ formatPrice(sum.total) }}</span>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterResult"
          :mobile-breakpoint="0"
          item-key="index"
          show-expand
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
        >
          <template v-slot:item.branch="{ item }">
            {{ item.branch || item.storeID }}
          </template>
          <template v-slot:item.point="{ item }">
            <td>
              <div :class="item.point > 0 ? 'red--text' : 'green--text'">
                {{ item.point }}
              </div>
            </td>
          </template>
          <template v-slot:item.action="{ item }">
            <td>
              <div>
                <v-btn
                  v-if="item.branch === '線上儲值'"
                  dense
                  color="warning"
                  small
                  disabled
                >
                  退點
                </v-btn>
                <v-btn
                  v-else-if="item.type === '儲存點數'"
                  dense
                  color="warning"
                  small
                  @click="cancelStorePoint(item)"
                  :disabled="!allowCancelStore"
                >
                  退點
                </v-btn>
                <v-btn
                  v-else-if="item.point !== 0"
                  dense
                  color="error"
                  small
                  @click="cancelTradePoint(item)"
                  :disabled="!allowCancel(item.createdAt)"
                >
                  退兌
                </v-btn>
              </div>
            </td>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <pre>{{ pretty(item.payload) }}</pre>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";

export default {
  name: "PointLog",
  data: () => ({
    date: [],
    menu: false,
    result: null,
    action: null,
    keyword: null,
    branch: null,
    branchList: [
      { text: "全部", value: null },
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
      { text: "線上儲值", value: "線上儲值" },
    ],
    headers: [
      { text: "序號", value: "index" },
      { text: "會員", value: "userID" },
      { text: "店點", value: "branch" },
      {
        text: "點數",
        value: "point",
        sort: (a, b) => a - b,
      },
      { text: "異動後點數", value: "balance" },
      { text: "兌換時間", value: "createdAt" },
      { text: "動作", value: "action" },
    ],
  }),
  created() {
    this.date[0] = dayjs().format("YYYY-MM-01");
    this.date[1] = dayjs().endOf("month").format("YYYY-MM-DD");
  },
  computed: {
    sum() {
      return {
        plus: _.sum(
          this.filterResult.map((o) => o.point).filter((o) => o > 0),
          "point"
        ),
        minus: _.sum(
          this.filterResult.map((o) => o.point).filter((o) => o < 0),
          "point"
        ),
        total: _.sum(
          this.filterResult.map((o) => o.point),
          "point"
        ),
      };
    },
    formatedDate() {
      return this.date[0] && this.date[1]
        ? dayjs(this.date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(this.date[1]).format("YYYY-MM-DD")
        : "";
    },
    filterResult() {
      let list = this.result || [];
      const bossID = "imoccfdc5cd73294";
      list = list.filter((row) => row.userID !== bossID);

      if (this.keyword) {
        list = list.filter(
          (row) =>
            row.userID?.includes(this.keyword) ||
            row.payload?.includes(this.keyword)
        );
      }
      if (this.branch) {
        list = list.filter(
          (row) => row.branch == this.branch || row.storeID == this.branch
        );
      }
      return list;
    },
  },
  methods: {
    allowCancelStore() {
      return ["管理者", "店長", "主管"].includes(this.$store.state.user.角色);
    },
    allowCancel(date) {
      // 線上集點訂單不可從此頁面退兌
      if (!this.isOnSiteTrade) return false;

      // 管理階級可直接退兌
      if (["管理者", "店長", "主管"].includes(this.$store.state.user.角色)) {
        return true;
      }

      // 店員在 1 小時內可退兌
      if (this.$store.state.user.角色 === "店員") {
        const now = dayjs();
        const tradeTime = dayjs(date);
        if (!now.isAfter(tradeTime.add(1, "hour"))) {
          return true;
        }
      }
      return false;
    },
    isOnSiteTrade(item) {
      return item.payload?.product?.線上兌換 === true;
    },
    async cancelStorePoint(item) {
      const reason = await this.$dialog.prompt({
        text: `請輸入取消原因`,
        title: "確定要取消兌換？",
        waitForResult: true,
      });

      if (reason === false) {
        return;
      }
      if (!reason && reason !== false) {
        await this.$toast.error("請輸入取消原因");
        return;
      }

      try {
        await this.axios.post(`/points/store-cancel/${item._id}`, { reason });
        await this.$toast.success("退點成功");
      } catch (error) {
        console.log(error);
        await this.$toast.error("退點失敗，請聯繫管理者！");
      }

      await this.search();
    },
    async cancelTradePoint(item) {
      const payload = JSON.parse(item.payload);

      let reason = await this.$dialog.prompt({
        text: `請輸入取消原因`,
        title: "確定要取消兌換？",
        waitForResult: true,
      });

      if (reason === false) {
        return;
      }
      if (!reason && reason !== false) {
        await this.$toast.error("請輸入取消原因");
        return;
      }

      try {
        await this.axios.post(`/dashboard/cancelPoint/${item._id}`, { reason });

        //確認退兌成功後回補庫存
        if (item.point < 0) {
          const process = payload.map((log) => {
            this.axios.post(`/transaction`, {
              type: "退貨",
              from: "客人",
              to: item.branch,
              productId: log.product._id,
              amount: log.amount,
              comment: `客人帳號：${item.userID}`,
            });
          });
          await Promise.all(process);
        }

        await this.$toast.success("退兌成功");
      } catch (error) {
        console.log(error);
        await this.$toast.error("退兌失敗，請聯繫管理者！");
      }

      await this.search();
    },
    formatPrice(value) {
      return new Intl.NumberFormat().format(value);
    },
    pretty: (val, indent = 2) => {
      // if (typeof val !== "object") {
      try {
        val = JSON.parse(val);
      } catch (err) {
        return val;
      }

      return JSON.stringify(val, null, indent);
      // }
    },
    async search() {
      if (!this.date) {
        return false;
      }
      this.$vloading.show();

      let result = (
        await this.axios.post(`/dashboard/pointLog`, { date: this.date })
      ).data;
      result.map((o) => {
        o.payload =
          typeof o.payload == "object" ? JSON.stringify(o.payload) : o.payload;
        return o;
      });

      this.result = result.reverse();
      this.$vloading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
