import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";

Vue.use(VueRouter);

const routes = [
  {
    path: "/barcode",
    name: "Barcode",
    meta: {
      title: "集點系統 - 會員條碼",
      isLiffEntry: true,
    },
    component: () => import("../views/Barcode.vue"),
  },
  {
    path: "/qrcode",
    name: "Qrcode",
    meta: {
      title: "集點系統 - 會員條碼",
      isLiffEntry: true,
    },
    component: () => import("../views/Qrcode.vue"),
  },
  {
    path: "/product",
    name: "Product",
    meta: {
      title: "集點系統 - 兌換商品",
      isLiffEntry: true,
    },
    component: () => import("../views/Product.vue"),
    beforeEnter: checkStoreQuery,
  },
  {
    path: "/history",
    name: "History",
    meta: {
      title: "集點系統 - 兌換記錄",
      isLiffEntry: true,
    },
    component: () => import("../views/History.vue"),
  },
  {
    path: "/ballHistory",
    name: "BallHistory",
    meta: {
      title: "集點系統 - 兌換記錄",
    },
    component: () => import("../views/BallHistory.vue"),
  },
  {
    path: "/dev",
    name: "dev",
    meta: {
      title: "集點系統 - 線上一番賞",
      isLiffEntry: true,
    },
    component: () => import("@/views/Client/IchibanList.vue"),
    // component: () => import("../views/History.vue"),
    beforeEnter: checkStoreQuery,
  },
  {
    path: "/owner",
    name: "Owner",
    meta: {
      title: "集點系統 - 使用者資訊",
      isLiffEntry: true,
    },
    component: () => import("@/views/Client/Owner.vue"),
    beforeEnter: checkStoreQuery,
  },
  {
    path: "/stageOwner",
    name: "StageOwner",
    meta: {
      title: "集點系統 - 使用者資訊",
      // isLiffEntry: true,
    },
    component: () => import("@/views/Client/Owner.vue"),
    beforeEnter: checkStoreQuery,
  },
  {
    path: "/topUp",
    name: "topUp",
    meta: {
      title: "線上一番賞 - 儲值點數",
    },
    component: () => import("@/views/Client/TopUp.vue"),
  },
  {
    path: "/ichibanPayment",
    name: "ichibanPayment",
    meta: {
      title: "線上一番賞 - 儲值訂單",
    },
    component: () => import("@/views/Client/IchibanPayment.vue"),
  },
  {
    path: "/StageIchiban",
    name: "StageIchiban",
    meta: {
      title: "集點系統 - 線上一番賞",
      isLiffEntry: true,
    },
    component: () => import("@/views/Client/IchibanList.vue"),
    beforeEnter: checkStoreQuery,
  },
  {
    path: "/ichiban",
    name: "Ichiban",
    meta: {
      title: "集點系統 - 線上一番賞",
      isLiffEntry: true,
    },
    component: () => import("@/views/Client/IchibanList.vue"),
    beforeEnter: checkStoreQuery,
  },
  {
    path: "/ichibanLiked",
    name: "ichibanLiked",
    meta: {
      title: "線上一番賞",
    },
    component: () => import("@/views/Client/IchibanLiked.vue"),
  },
  {
    path: "/ichiban/:id",
    name: "IchibanPage",
    meta: {
      title: "集點系統 - 線上一番賞",
    },
    component: () => import("@/views/Client/IchibanPage.vue"),
  },
  {
    path: "/branch/:branch",
    name: "BranchIchiban",
    meta: {
      title: "集點系統 - 線上一番賞",
    },
    component: () => import("@/views/Client/BranchIchibanList.vue"),
  },
  {
    path: "/ichibanHistory",
    name: "ichibanHistory",
    meta: {
      title: "集點系統 - 線上一番賞紀錄",
    },
    component: () => import("@/views/Client/IchibanHistory.vue"),
  },
  {
    path: "/ichibanDelivery",
    name: "ichibanDelivery",
    meta: {
      title: "集點系統 - 線上一番賞紀錄",
    },
    component: () => import("@/views/Client/IchibanDelivery.vue"),
  },
  {
    path: "/ichibanUser",
    name: "ichibanUser",
    meta: {
      title: "集點系統 - 使用者資訊",
    },
    component: () => import("@/views/Client/IchibanUserInfo.vue"),
  },

  {
    path: "/dashboard",
    component: require("@/views/Dashboard/Login.vue").default,
    meta: {
      title: "集點系統 - 登入",
    },
  },
  {
    path: "/dashboard",
    component: require("@/views/Dashboard/Template.vue").default,
    children: [
      {
        path: "tradePoint",
        name: "tradePoint",
        component: require("@/views/Dashboard/TradePoint.vue").default,
        meta: {
          title: "集點系統 - 兌換點數",
        },
      },
      {
        path: "tradeProduct",
        name: "tradeProduct",
        component: require("@/views/Dashboard/TradeProduct.vue").default,
        meta: {
          title: "集點系統 - 兌換商品",
        },
      },
      {
        path: "tradeLottery",
        name: "tradeLottery",
        component: require("@/views/Dashboard/TradeLottery.vue").default,
        meta: {
          title: "集點系統 - 換抽商品",
        },
      },
      {
        path: "ball",
        name: "ball",
        component: require("@/views/Dashboard/Ball.vue").default,
        meta: {
          title: "集點系統 - 彈珠彩票兌換",
        },
      },
      {
        path: "ballLog",
        name: "ballLog",
        component: require("@/views/Dashboard/BallLog.vue").default,
        meta: {
          title: "集點系統 - 兌換記錄",
        },
      },
      {
        path: "ballCash",
        name: "ballCash",
        component: require("@/views/Dashboard/BallCash.vue").default,
        meta: {
          title: "集點系統 - 出納記錄",
        },
      },
      {
        path: "ballUser",
        name: "ballUser",
        component: require("@/views/Dashboard/BallUser.vue").default,
        meta: {
          title: "集點系統 - 用戶列表",
        },
      },
      {
        path: "order",
        name: "order",
        component: require("@/views/Dashboard/Order.vue").default,
        meta: {
          title: "集點系統 - 訂單管理",
        },
      },
      {
        path: "pointLog",
        name: "pointLog",
        component: require("@/views/Dashboard/PointLog.vue").default,
        meta: {
          title: "集點系統 - 點數記錄",
        },
      },
      {
        path: "ppLog",
        name: "ppLog",
        component: require("@/views/Dashboard/PPLog.vue").default,
        meta: {
          title: "集點系統 - 出點記錄",
        },
      },
      {
        path: "pointExchangeLog/:type",
        name: "pointExchangeLog/:type",
        component: require("@/views/Dashboard/PointExchangeLog.vue").default,
        meta: {
          title: `兌換紀錄（出點、出貨）`,
        },
      },
      {
        path: "lotteryLog",
        name: "lotteryLog",
        component: require("@/views/Dashboard/LotteryLog.vue").default,
        meta: {
          title: `換抽紀錄`,
        },
      },
      {
        path: "machine",
        name: "machine",
        component: require("@/views/Dashboard/Machine.vue").default,
        meta: {
          title: "集點系統 - 機台記錄",
        },
      },
      {
        path: "machineStatistic",
        name: "machineStatistic",
        component: require("@/views/Dashboard/MachineStatistic.vue").default,
        meta: {
          title: "集點系統 - 機台統計",
        },
      },
      {
        path: "exchangeLog",
        name: "exchangeLog",
        component: require("@/views/Dashboard/ExchangeLog.vue").default,
        meta: {
          title: "集點系統 - 兌換記錄",
        },
      },
      {
        path: "systemLog",
        name: "systemLog",
        component: require("@/views/Dashboard/SystemLog.vue").default,
        meta: {
          title: "集點系統 - 系統記錄",
        },
      },
      {
        path: "product",
        name: "product",
        meta: {
          title: "商品管理",
        },
        component: () => import("../views/Dashboard/Product.vue"),
      },
      {
        path: "ichiban",
        name: "ichiban",
        meta: {
          title: "一番賞管理",
        },
        component: () => import("../views/Dashboard/ichiban/Ichibans.vue"),
      },
      {
        path: "ichibanPending",
        name: "ichibanPending",
        meta: {
          title: "格主一番賞管理",
        },
        component: () =>
          import("../views/Dashboard/ichiban/IchibanPending.vue"),
      },
      {
        path: "tradeIchiban",
        name: "tradeIchiban",
        meta: {
          title: "購買線下一番賞",
        },
        component: () => import("../views/Dashboard/TradeIchiban.vue"),
      },
      {
        path: "ichibanRecord",
        name: "ichibanRecord",
        meta: {
          title: "一番賞紀錄",
        },
        component: () => import("../views/Dashboard/ichiban/Records.vue"),
      },
      {
        path: "ichibanOrder",
        name: "ichibanOrder",
        meta: {
          title: "線上一番賞訂單管理",
        },
        component: () => import("../views/Dashboard/ichiban/Order.vue"),
      },
      {
        path: "ichibanLock",
        name: "ichibanLock",
        meta: {
          title: "一番賞鎖定管理",
        },
        component: () => import("../views/Dashboard/ichiban/IchibanLock.vue"),
      },
      {
        path: "ichibanConfig",
        name: "ichibanConfig",
        meta: {
          title: "線上一番賞共用圖片",
        },
        component: () => import("@/views/Dashboard/ichiban/Config.vue"),
      },
      {
        path: "ichibanBanner",
        name: "ichibanBanner",
        meta: {
          title: "線上一番賞公告管理",
        },
        component: () => import("../views/Dashboard/ichiban/Banner.vue"),
      },
      {
        path: "ichibanCategory",
        name: "ichibanCategory",
        meta: {
          title: "線上一番賞類別管理",
        },
        component: () =>
          import("../views/Dashboard/ichiban/IchibanCategory.vue"),
      },
      {
        path: "ichibanOwners",
        name: "ichibanOwners",
        meta: {
          title: "一番賞格主管理",
        },
        component: () => import("../views/Dashboard/ichiban/Owners.vue"),
      },
      {
        path: "ichibanOwnerStock",
        name: "ichibanOwnerStock",
        meta: {
          title: "一番賞格主庫存管理",
        },
        component: () => import("../views/Dashboard/ichiban/OwnerStock.vue"),
      },
      {
        path: "stall",
        name: "stall",
        meta: {
          title: "機台管理",
        },
        component: () => import("../views/Dashboard/Stall.vue"),
      },
      {
        path: "lottery",
        name: "lottery",
        meta: {
          title: "換抽管理",
        },
        component: () => import("../views/Dashboard/Lottery.vue"),
      },
      {
        path: "purchase",
        name: "purchase",
        meta: {
          title: "請購單管理",
        },
        component: () => import("../views/Dashboard/Purchase.vue"),
      },
      {
        path: "purchaseHistory",
        name: "purchaseHistory",
        meta: {
          title: "歷史請購單",
        },
        component: () => import("../views/Dashboard/PurchaseHistory.vue"),
      },
      {
        path: "purchaseOrder",
        name: "purchaseOrder",
        meta: {
          title: "採購單管理",
        },
        component: () => import("../views/Dashboard/PurchaseOrder.vue"),
      },
      {
        path: "purchaseOrderHistory",
        name: "purchaseOrderHistory",
        meta: {
          title: "歷史採購單",
        },
        component: () => import("../views/Dashboard/PurchaseOrderHistory.vue"),
      },
      {
        path: "incomeStatement",
        name: "incomeStatement",
        meta: {
          title: "櫃檯帳表",
        },
        component: () => import("../views/Dashboard/IncomeStatement.vue"),
      },
      {
        path: "incomeStatementManage",
        name: "incomeStatementManage",
        meta: {
          title: "櫃檯帳表管理",
        },
        component: () => import("../views/Dashboard/IncomeStatementManage.vue"),
      },
      {
        path: "payable",
        name: "payable",
        meta: {
          title: "待付貨款",
        },
        component: () => import("../views/Dashboard/Payable.vue"),
      },
      {
        path: "payableLog",
        name: "payableLog",
        meta: {
          title: "貨款紀錄",
        },
        component: () => import("../views/Dashboard/PayableLog.vue"),
      },
      {
        path: "stock",
        name: "stock",
        meta: {
          title: "庫存管理",
        },
        component: () => import("../views/Dashboard/Stock.vue"),
      },
      {
        path: "stock/:branch",
        name: "foreignBranchStock",
        meta: {
          title: "庫存管理",
        },
        component: () => import("../views/Dashboard/branch/Stock.vue"),
      },
      {
        path: "stockAll",
        name: "stockAll",
        meta: {
          title: "庫存總覽",
        },
        component: () => import("../views/Dashboard/StockAll.vue"),
      },
      {
        path: "transactionHistory",
        name: "transactionHistory",
        meta: {
          title: "庫存異動紀錄",
        },
        component: () => import("../views/Dashboard/TransactionHistory.vue"),
      },
      {
        path: "inventory",
        name: "inventory",
        meta: {
          title: "盤點紀錄",
        },
        component: () => import("../views/Dashboard/Inventory.vue"),
      },
      {
        path: "period",
        name: "period",
        meta: {
          title: "檔期管理",
        },
        component: () => import("../views/Dashboard/Period.vue"),
      },
      {
        path: "periodProfit",
        name: "periodProfit",
        meta: {
          title: "檔期盈虧",
        },
        component: () => import("../views/Dashboard/Profit.vue"),
      },
      {
        path: "payment",
        name: "payment",
        meta: {
          title: "支出管理",
        },
        component: () => import("../views/Dashboard/Payment.vue"),
      },
      {
        path: "trade",
        name: "trade",
        meta: {
          title: "賣貨紀錄",
        },
        component: () => import("../views/Dashboard/Trade.vue"),
      },
      {
        path: "clawSystemLog",
        name: "clawSystemLog",
        meta: {
          title: "店員兌換記錄",
        },
        component: () => import("../views/Dashboard/ClawSystemLog.vue"),
      },
      {
        path: "users",
        name: "users",
        meta: {
          title: "會員資料",
        },
        component: () => import("../views/Dashboard/Users.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.afterEach((to, from) => {
  if (to.meta.title) document.title = to.meta.title;
});

function checkStoreQuery(to, from, next) {
  let state = to.query["liff.state"]
    ? decodeURIComponent(to.query["liff.state"])
    : null;
  let store = to.query.store || state?.replace("?store=", "");

  if (!store) {
    next({ ...to, query: { ...to.query, store: "@695yimoc" } });
    return;
  }
  next();
}

export default router;
