<template>
  <v-container fluid>
    <h3 class="page-title">機台記錄</h3>
    <v-row>
      <v-col cols="6" md="3">
        <v-select
          label="店點"
          v-model="branch"
          :items="[
            { text: '請選擇', value: null, disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
          ]"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="日期"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            locale="zh-tw"
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :item-class="rowColor"
          :headers="headers"
          :items="data"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
          :height="'80vh'"
          fixed-header
        >
          <template v-slot:item.current.金額="props">
            <v-edit-dialog
              v-if="isMobile"
              :return-value.sync="props.item.current.金額"
            >
              {{ props.item.current.金額 || "- - -" }}

              <template v-slot:input>
                <v-text-field
                  class="my-3"
                  v-model.number="props.item.current.金額"
                  label="今日金額"
                  outlined
                  dense
                  hide-details
                  @change="updateValue(props.item, '金額')"
                  clearable
                  type="number"
                ></v-text-field>
              </template>
            </v-edit-dialog>
            <v-text-field
              v-else
              class="my-3 inputWidth"
              v-model.number="props.item.current.金額"
              label="今日金額"
              outlined
              dense
              hide-details
              @change="updateValue(props.item, '金額')"
              clearable
              type="number"
            ></v-text-field>
          </template>
          <template v-slot:item.current.電眼="props">
            <v-edit-dialog
              v-if="isMobile"
              :return-value.sync="props.item.current.電眼"
            >
              {{ props.item.current.電眼 || "- - -" }}

              <template v-slot:input>
                <v-text-field
                  class="my-3"
                  v-model.number="props.item.current.電眼"
                  label="今日電眼"
                  outlined
                  dense
                  hide-details
                  @change="updateValue(props.item, '電眼')"
                  clearable
                  type="number"
                ></v-text-field>
              </template>
            </v-edit-dialog>
            <v-text-field
              v-else
              class="my-3 inputWidth"
              v-model.number="props.item.current.電眼"
              label="今日電眼"
              outlined
              dense
              hide-details
              @change="updateValue(props.item, '電眼')"
              clearable
              type="number"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import machineNum from "@/config/machineNum.json";

export default {
  name: "Machine",
  data: () => ({
    isMobile: false,
    ready: false,
    branch: null,
    date: null,
    menu: false,
    data: [],
    headers: [
      { text: "機台", value: "machine", align: "center", sortable: false },
      {
        text: "昨日金額",
        value: "prev.金額",
        align: "center",
        sortable: false,
      },
      {
        text: "今日金額",
        value: "current.金額",
        align: "center",
        sortable: false,
      },
      {
        text: "昨日電眼",
        value: "prev.電眼",
        align: "center",
        sortable: false,
      },
      {
        text: "今日電眼",
        value: "current.電眼",
        align: "center",
        sortable: false,
      },
    ],
  }),
  created() {
    this.branch = this.$store.state.branch || this.$store.state.storeID;
    this.date =
      dayjs().hour() >= 12
        ? dayjs().format("YYYY-MM-DD")
        : dayjs().subtract(1, "day").format("YYYY-MM-DD");
    this.$nextTick(() => {
      this.ready = true;
      this.load();
    });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    rowColor(item) {
      return (item.prev.金額 &&
        item.current.金額 &&
        item.prev.金額 > item.current.金額) ||
        (item.prev.電眼 &&
          item.current.電眼 &&
          item.prev.電眼 > item.current.電眼)
        ? "error-row"
        : "";
    },
    async load() {
      if (!this.ready) return;
      if (!this.branch || !this.date) {
        this.$toast.error(`請選擇店點及日期`);
        return;
      }
      let dateMinus = dayjs(this.date).add(-1, "day").format("YYYY-MM-DD");
      const res = await this.axios.get(
        `/dashboard/machine/${this.$store.state.user.store}/${this.branch}/${dateMinus},${this.date}`
      );
      let records = res.data;
      records = records.map((item) => ({
        ...item,
        machine: item.machine.toString(),
      }));
      let store = this.$store.state.user.store;
      let branch = this.branch;
      let data = [];
      machineNum[this.branch].forEach((i) => {
        let payload = {
          store,
          branch,
          machine: i,
        };
        payload[`prev`] = _.find(records, {
          store,
          branch,
          machine: i,
          date: dayjs(this.date).subtract(1, "day").format("YYYY-MM-DD"),
        }) || { 金額: null, 電眼: null };
        payload[`current`] = _.find(records, {
          store,
          branch,
          machine: i,
          date: dayjs(this.date).format("YYYY-MM-DD"),
        }) || { 金額: null, 電眼: null };
        data.push(payload);
      });
      this.data = data;
    },
    async updateValue(props, type) {
      let data = _.pick(props, ["store", "branch", "machine"]);
      data.date = this.date;
      data.payload = _.pick(props.current, [type]);
      await this.axios.post(`/dashboard/machine`, data);
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
    date() {
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
.inputWidth {
  width: 130px;
  margin: 0 auto;
}
::v-deep tr.error-row {
  background: #ff5252 !important;
  color: #fff;
  font-weight: bold;
}
</style>
