import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

Vue.use(Vuex);

const clientModule = {
  namespaced: true,
  state: {
    userID: null,
    UID: null,
    user: null,
    token: null,
    store: null,
  },
  mutations: {
    setState(state, object) {
      _.forEach(object, function (value, key) {
        // state[key] = value;
        Vue.set(state, key, value);
      });
    },
    updateState(state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {},
};

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    drawerState: true,
    token: null,
    user: {},
    person: null,
    input: {},
    storeID: "",
    branch: "",
  },
  mutations: {
    updateField,
    setState(state, object) {
      _.forEach(object, function (value, key) {
        state[key] = value;
      });
    },
    toggleDrawerState(state, data) {
      state.drawerState = data;
    },
  },
  getters: {
    getField,
    drawerState: (state) => state.drawerState,
  },
  actions: {},
  modules: { clientModule },
});

// store.registerModule("clientModule", clientModule);

export default store;
