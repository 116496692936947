<template>
  <v-container fluid>
    <h3 class="page-title">訂單管理</h3>
    <v-row>
      <v-col cols="4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="日期區間"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateRange"
            range
            no-title
            @input="checkClose"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="關鍵字"
          v-model.trim="keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-btn color="primary" class="" @click="search">查詢</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="records"
          no-data-text="無訂單"
          :mobile-breakpoint="0"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
        >
          <template v-slot:item.序號="{ index }"> {{ index + 1 }} </template>
          <template v-slot:item.狀態="{ item }">
            <v-select
              v-model="item.status"
              dense
              hide-details
              outlined
              @change="updateStatus(item)"
              :disabled="item.status == 'strike' || item.status == 'cancel'"
              :items="status"
            ></v-select>
          </template>
          <template v-slot:item.兌換商品="{ item }">
            <div v-if="item.payload.items" class="d-flex flex-column">
              <span v-for="product of getOrderSum(item.payload.items)">
                {{ product.商品名稱 }} x {{ product.amount }}
              </span>
            </div>
            <div v-else>
              {{ item.payload.product.商品名稱 }} x {{ item.payload.number }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "Order",
  data: () => ({
    dateRange: [],
    menu: false,
    keyword: "",
    records: [],
    headers: [
      { text: "序號", value: "序號" },
      { text: "姓名", value: "payload.name" },
      { text: "電話", value: "payload.phone", sortable: false },
      { text: "地址", value: "payload.address", sortable: false },
      { text: "兌換點數", value: "point", sortable: false },
      { text: "兌換商品", value: "兌換商品", sortable: false },
      { text: "兌換時間", value: "createdAt" },
      { text: "狀態", value: "狀態", width: "200px" },
    ],
    status: [
      { text: "待處理", value: "pending" },
      { text: "處理中", value: "process" },
      { text: "處理完畢", value: "complete" },
      { text: "取消", value: "cancel" },
      { text: "沖帳", value: "strike", disabled: true },
    ],
  }),
  async created() {
    this.dateRange[0] = dayjs().add(-7, "day").format("YYYY-MM-DD");
    this.dateRange[1] = dayjs().format("YYYY-MM-DD");
    await this.search();
  },

  computed: {
    dateRangeText: {
      get() {
        return this.dateRange.join(" ~ ");
      },
      set() {
        this.dateRange = [];
      },
    },
  },
  methods: {
    checkClose() {
      if (this.dateRange.length == 2) {
        this.menu = false;
      }
    },
    async search() {
      let { data } = await this.axios.get("/dashboard/order", {
        params: { dateRange: this.dateRange, keyword: this.keyword },
      });
      this.records = data;
    },
    async updateStatus(order) {
      if (order.status == "cancel") {
        return this.cancel(order);
      }
      this.$vloading.show();
      try {
        await this.axios.post("/dashboard/order", {
          _id: order._id,
          status: order.status,
        });
        this.$toast.success("更新完成！");
        await this.search();
      } catch (error) {
        this.$toast.error("更新失敗！");
      } finally {
        this.$vloading.hide();
      }
    },
    async cancel(order) {
      if (
        await this.$dialog.confirm({
          text: `確定要取消訂單？`,
        })
      ) {
        this.$vloading.show();
        try {
          await this.axios.delete("/dashboard/order", {
            params: { _id: order._id },
          });
          this.$toast.success("取消完成！");
          await this.search();
        } catch (error) {
          this.$toast.error("取消失敗！");
        } finally {
          this.$vloading.hide();
        }
      }
    },
    getOrderSum(items) {
      const group = _.groupBy(items, (product) => product.id);
      return Object.values(group).map((array) => {
        return { ...array[0], amount: array.length };
      });
    },
  },
};
</script>

<style></style>
